<template>
  <div class="studio-chart-wrapper">
    <div v-if="loading">
      <ScreenLoader />
    </div>
    <div v-else>
      <div v-if="sampleError" class="text-center">{{ sampleError }}</div>
      <div v-else>
        <div class="main-chart unique-screen">
          {{ chartName }}
          <div :ref="chartId"></div>
          <v-btn @click="openModal" v-if="chartType" flat size="small"
            class="chart-preview-btn2 text-none"><img :src="expandIcon" alt="Expand Icon" width="16" height="16" class="mr-2">Expand</v-btn>
          <v-dialog v-model="isModalOpen" width="100vw">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal" flat icon size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="modalChartId"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ScreenLoader from "../Loaders/ScreenLoader.vue";

import downloadIcon from '../../../assets/Images/csv.svg';

export default {
  name: "DynamicChart",
  components: {
    ScreenLoader
  },
  props: {
    loading: { type: Boolean, default: true },
    chartName: { type: String, default: "" },
    chartType: { type: String, required: true },
    xAxisData: { type: Array, default: () => [] },
    yAxisData: { type: Array, default: () => [] },
    zAxisData: { type: Array, default: () => [] },
    xColorGroupData: { type: Array, default: [] },
    yColorGroupData: { type: Array, default: [] },
    zColorGroupData: { type: Array, default: [] },
    colorGroupKey: { type: String, default: "" },
    sampleError: { type: String, default: "" },
    markerSizes: Array,
    xAxisDisplayName: { type: String, default: "" },
    yAxisDisplayNames: { type: Array, default: () => [] },
    xBins: { type: Number, default: 20 },
    yBins: { type: Number, default: 20 },
  },
  data() {
    return {
      chartId: "dynamicChart",
      modalChartId: "modalChart",
      isModalOpen: false,
      hasValidXColorGroupData: false,
      hasValidYColorGroupData: false,
      resizeObserver: null,
      previousSidebarState: null,
      expandIcon: require("../../../assets/Images/Assets/maximize-2.png"),
    };
  },
  watch: {
    xAxisData: "plotChart",
    yAxisData: "plotChart",
    zAxisData: "plotChart",
    xColorGroupData: "plotChart",
    yColorGroupData: "plotChart",
    zColorGroupData: "plotChart",
    chartType: "plotChart",
    xBins: "plotChart",
    yBins: "plotChart",
  },
  methods: {
    downloadCSV() {
  const rows = [];
  
  // Add header row
  const headers = [this.xAxisDisplayName, ...this.yAxisDisplayNames].join(",");
  rows.push(headers);

  // Determine the maximum length for iteration
  const maxLength = Math.max(this.xAxisData.length, this.yAxisData.reduce((max, arr) => Math.max(max, arr.length), 0));

  for (let i = 0; i < maxLength; i++) {
    // Get the x value, defaulting to an empty string if undefined
    const x = this.xAxisData[i] !== undefined ? this.xAxisData[i] : "";

    // Collect the y values for this row
    const yValues = this.yAxisData.map(yDataArray => {
      return yDataArray[i] !== undefined ? yDataArray[i] : "";
    });

    // Add data row with x and corresponding y values
    rows.push([x, ...yValues].join(","));
  }

  // Create CSV Blob
  const csvContent = rows.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${this.chartName || "chart"}.csv`);
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
},
    plotChart() {
      if (!this.$refs[this.chartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();
      Plotly.newPlot(this.$refs[this.chartId], traces, layout, config);
      this.resizeChart();
      this.setupResizeObserver();
      this.setupSidebarStateCheck();
    },
    plotModalChart() {
      if (!this.$refs[this.modalChartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();

      this.$nextTick(() => {
        Plotly.newPlot(this.$refs[this.modalChartId], traces, layout, config)
      });
    },

    generateTraces() {
      const traceType = this.getTraceType();
      const traceMode = this.getTraceMode();
      const scatterMarkerSizes = 8;

      this.hasValidXColorGroupData = Object.values(this.xColorGroupData).some(group => Array.isArray(group) && group.length > 0);
      this.hasValidYColorGroupData = Object.values(this.yColorGroupData).some(group => Array.isArray(group) && group.length > 0);

      if (this.chartType === "Histogram") {
        // Handled Histogram separately
        if (this.hasValidXColorGroupData) {
          return Object.keys(this.xColorGroupData).map((key) => {
            const xData = this.xColorGroupData[key];
            const trace = {
              x: xData,
              type: traceType,
              autobinx: false,
              mode: traceMode,
              name: key,
              xbins: { size: this.calculateBinSize(xData, this.xBins) }
            };
            return trace;
          })
        }
        else {
          return [{
            x: this.xAxisData,
            type: traceType,
            name: this.chartName,
            autobinx: false,
            xbins: { size: this.calculateBinSize(this.xAxisData, this.xBins) }
          }];
        }
      } else if (this.hasValidXColorGroupData && this.hasValidYColorGroupData) {
        // Generate traces for each pair of xColorGroupData and yColorGroupData
        return Object.keys(this.xColorGroupData).map((key) => {
          const xData = this.xColorGroupData[key];
          let yData = this.yColorGroupData[key];
          const zData = this.zColorGroupData[key];
          const markerSize = (this.chartType === "Scatter Plot") ? scatterMarkerSizes : this.markerSizes;

          // Ensure xData and yData are arrays and have the same length
          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]); // Assuming each item is an array with one value

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize,
            },
            name: key  // Use the key as the legend label
          };

          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (this.chartType === "2D Histogram") {
            // trace.colorscale= "Blues"
            // trace.reversescale=true
            trace.colorscale = [
              [0.0, 'rgba(247,251,255,255)'],
              [0.25, 'rgba(193,217,237,255)'], 
              [0.5, 'rgba(97,167,210,255)'],  
              [0.75, 'rgba(24,101,172,255)'],    
              [1.0, 'rgba(8,48,108,255)'] 
            ]
            trace.colorbar = {
        title: {
            text: 'Number of Samples', // Title for the color bar
            side: 'right',
        }
    };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }

          return trace;
        }).filter(trace => trace !== null);
      }
      else {
        return this.yAxisData.map((yData, index) => {
          const xData = this.xAxisData;
          const zData = this.zAxisData;

          // Check if any of the data arrays are invalid
          const isInvalidData = (data) => !data || data.every(value => value === null || value === undefined);
    
           if (isInvalidData(xData) || isInvalidData(yData)) {
             console.log("Invalid data detected: Chart will not be plotted.");
             return null; // Skip plotting for invalid data
           }

          const markerSize = (this.chartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize
            },
            name: this.yAxisDisplayNames[index] || `Series ${index + 1}`
          };
          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (this.chartType === "2D Histogram") {
            trace.colorscale = [
              [0.0, 'rgba(247,251,255,255)'],
              [0.25, 'rgba(193,217,237,255)'], 
              [0.5, 'rgba(97,167,210,255)'],  
              [0.75, 'rgba(24,101,172,255)'],    
              [1.0, 'rgba(8,48,108,255)'] 
         ];
           trace.colorbar = {
            title: {
              text: 'Number of Samples', // Title for the color bar
              side: 'right',
            }
           };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }

    // Return the trace if valid, else skip it
    return trace;
        }).filter(trace => trace !== null); // Ensure no null traces are included in the final array
      }
    },
    generateLayout() {
      return {
        // title: this.getLayoutTitle(),
        xaxis: { title: this.xAxisDisplayName },
        yaxis: { title: this.yAxisDisplayNames.join(', ') },
        legend: {
          title: {
            text: this.hasValidXColorGroupData && this.hasValidYColorGroupData ? this.colorGroupKey : ''
          }
        },
      };
    },
    generateConfig() {
      return {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d'],
        modeBarButtonsToAdd: [
          {
            name: "Download CSV",
             icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.downloadCSV,
          },
        ],
      };
    },
    getTraceType() {
      switch (this.chartType) {
        case "Line Chart": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },
    getTraceMode() {
      if (this.chartType === "Bubble") return "markers";
      if (this.chartType === "Scatter Plot") return "markers";
      return undefined;
    },
    getLayoutTitle() {
      const titles = {
        "Line Chart": "Line Chart",
        "Bar Chart": "Bar Chart",
        "Histogram": "Histogram Chart",
        "Bubble": "Bubble Chart",
        "3D Scatter": "3D Scatter Chart",
        "Scatter Plot": "Scatter Chart",
        "2D Histogram": "2D Histogram Chart",
        "Heatmap": "Heatmap Chart",
        "Treemap": "Treemap Chart"
      };
      return titles[this.chartType] || "";
    },
    calculateBinSize(data, bins) {
      const numericData = data.filter(value => typeof value === 'number' && !isNaN(value));
      if (numericData.length === 0) return 1;
      const minValue = Math.min(...numericData);
      const maxValue = Math.max(...numericData);
      return (maxValue - minValue) / bins;
    },
    openModal() {
      this.isModalOpen = true;
      this.$nextTick(this.plotModalChart);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    resizeChart() {
      if (this.$refs[this.chartId]) {
        Plotly.Plots.resize(this.$refs[this.chartId]);
      }
    },
    checkSidebarState() {
  const currentSidebarState = localStorage.getItem('sidebarState');
  if (this.previousSidebarState !== currentSidebarState) {
    this.previousSidebarState = currentSidebarState;
    // Add a small delay before resizing
    // setTimeout(() => {
      this.resizeChart();
    // }, 50);
  }
},
    setupSidebarStateCheck() {
      this.checkSidebarState();
      this.sidebarStateCheckInterval = setInterval(this.checkSidebarState, 50);
    },
    removeSidebarStateCheck() {
      clearInterval(this.sidebarStateCheckInterval);
    },
    setupResizeObserver() {
  setTimeout(() => {
    if (this.$refs[this.chartId]) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resizeChart();
      });

      this.$nextTick(() => {
        const chartElement = this.$refs[this.chartId];
        if (chartElement) {
          this.resizeObserver.observe(chartElement);
        } else {
          console.error("Chart element not found for ResizeObserver");
        }
      });
    }
  }, 100); // Adjust delay as needed
},
  removeResizeObserver() {
    if (this.resizeObserver && this.$refs[this.chartId]) {
      this.resizeObserver.unobserve(this.$refs[this.chartId]);
      this.resizeObserver.disconnect();
    }
  },
  },
  mounted() {
    this.$nextTick(() => {
      this.plotChart();
      this.setupResizeObserver();
    });
  },
  beforeDestroy() {
    this.removeResizeObserver();
    this.removeSidebarStateCheck();
  },
};
</script>