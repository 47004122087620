<template>
  <div class="chart-wrap">
    <v-row no-gutters>
      <v-col
        v-for="(chart, index) in Array(6).fill(null)"
        :key="index"
        cols="12"
        md="6"
        class="pa-2"
      >
      <!-- <div class="chart-column dashboard-chart-column mb-0"> -->
        <div
          :class="[
            'dashboard-chart-column mb-0',
            { 'chart-column': index < chartTypes.length,
              'selectbox-present': showSelectboxes
             }
          ]"
        >
        <div v-if="showSelectboxes" class="edit-dashboard-selectbox">
        <!-- <label for="">Select Saved Chart</label> -->
        <v-select
          clearable
          v-model="chartSelections[index]"
          item-value="Name"
          item-text="Name"
          placeholder="Select Saved Chart"
          :items="chartTypesNames"
          @update:modelValue="(value) => chartTypeChange(value, index)"
        ></v-select>
        </div>

        <!-- Show chart if it exists -->
        <div v-if="index < chartTypes.length">
          <div v-if="loading[index]">
            <DashboardCharts />
          </div>
          <div v-else>
            <div v-if="timeoutMessage[index]" class="main-chart timeout">
              <div class="text-center">
                {{ timeoutMessage[index] }}
                <v-icon
                  flat
                  @click="fetchChartsData(index)"
                  color="blue-darken-3"
                  icon="mdi-reload"
                  size="large"
                ></v-icon>
              </div>
            </div>
            <div v-else-if="apiError[index]" class="main-chart timeout">
              <div class="text-center" v-html="apiError[index]"></div>
            </div>
            <div v-else-if="noData[index]" class="tabs-no-test-div">
              <h6 v-html="noData[index]"></h6>
            </div>
            <div v-else>
              <div :ref="'chart_' + index" class="main-chart">
                <v-btn
                  v-if="dataLoaded[index]"
                  @click="openModal(index)"
                  flat
                  size="small"
                  class="chart-preview-btn2 text-none"
                >
                <img :src="expandIcon" alt="Expand Icon" width="16" height="16" class="mr-2">
                  Expand
                </v-btn>
              </div>
            </div>
          </div>

          <v-dialog v-model="isPreviewOpen[index]" width="100vw" class="multi-chart-preview-div composition">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal(index)" flat icon class="chart-preview-close-btn" size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="'modal_chart_' + index" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      </v-col>
    </v-row>

    <div v-if="chartTypes.length === 0" class="tabs-no-test-div">
      <h6>Chart Types are not available.</h6>
    </div>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import Plotly from "plotly.js-dist-min";
import DashboardCharts from "../Loaders/DashboardCharts.vue";
import axios from "axios";

import downloadIcon from '../../../assets/Images/csv.svg';

export default {
  name: "ChartsData",
  data() {
    return {
      tableData: [],
      loading: this.chartTypes.map(() => true),
      isPreviewOpen: this.chartTypes.map(() => false),
      dataLoaded: this.chartTypes.map(() => false),
      timeoutMessage: this.chartTypes.map(() => ""),
      apiError: this.chartTypes.map(() => ""),
      selectedColorGroupKeys: this.chartTypes.map(() => ""),
      selectedYAxisKeys: this.chartTypes.map(() => []),
      selectedXAxisKeys: this.chartTypes.map(() => []),
      selectedZAxisKeys: this.chartTypes.map(() => []),
      markerSizes: [],
      noData: this.chartTypes.map(() => ""),
      chartSelections: Array(6).fill(null), // For select box inputs
      chartTypesNames: [],
      selectedChartIds: [],
      showStudioDialog: false,
      expandIcon: require("../../../assets/Images/Assets/maximize-2.png"),
    };
  },
  components: {
    DashboardCharts,
  },
  props: {
    chartTypes: {
      type: Array,
      required: true,
    },
    showSelectboxes: {
      type: String,
      required: true,
    },
  },
  watch: {
  showSelectboxes() {
      this.fetchData();
  },
},
  methods: {
    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },
    chartTypeChange(selectedChartName, chartIndex) {

  // Handle "+ Create New Chart" logic early
  if (selectedChartName === "+ Create New Chart") {
    this.openStudioDataDialog();

    // Reset the specific chart selection to null dynamically
    if (chartIndex >= 0 && chartIndex < 6) {
      this.chartSelections[chartIndex] = null;
    }

    // Exit early to avoid further processing
    return;
  }

  // Handle case when the selection is cleared
  if (!selectedChartName) {

    // Remove the ID for this chartIndex from selectedChartIds
    this.selectedChartIds.splice(chartIndex, 1);

    // Reset the dropdown value
    this.chartSelections[chartIndex] = null;

    // Emit the updated selectedChartIds to the parent
    this.$emit("update-selected-chart-ids", this.selectedChartIds);

    return; // Exit early
  }

  const selectedChart = this.allChartTypes.find(
    (chart) => chart.Name === selectedChartName
  );
  
  if (!selectedChart) {
    console.error("Selected chart not found:", selectedChartName);
    return;
  }

  // Update selected chart ID for the given index
  this.selectedChartIds[chartIndex] = selectedChart.ID;

  // Filter out the selected chart IDs and prepare available chart names
  const availableCharts = this.allChartTypes.filter(
    (chart) => !this.selectedChartIds.includes(chart.ID)
  );

  this.chartTypesNames = availableCharts
    .map((chart) => chart.Name)
    .concat("+ Create New Chart");

  // Emit updated selectedChartIds to the parent
  this.$emit("update-selected-chart-ids", this.selectedChartIds);
  this.$nextTick(() => {
    this.fetchChartsData(chartIndex, selectedChart)
      });
},
openStudioDataDialog() {
      this.showStudioDialog = true;
      this.$emit("update-show-studio-dialog", this.showStudioDialog);
    },
getSavedCharts() {
  this.showLoader = true;
  const tenantId = localStorage.getItem("tenantId");
  axios
    .get(process.env.VUE_APP_API_URL + "public/api/v1/charts", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "X-TenantID": tenantId,
      },
    })
    .then((response) => {
      if (response.data.statusCode === 200) {
        this.allChartTypes = response.data.data.charts; // Store all chart types
        this.chartTypesNames = [
          ...this.allChartTypes.map((chart) => chart.Name),
          "+ Create New Chart"
        ];
      }
    })
    .catch((error) => {
      console.error("Error fetching charts:", error);
    })
    .finally(() => {
      this.showLoader = false;
    });
},
    fetchData() {
  const tenantId = localStorage.getItem("tenantId");
  this.showLoader = true;
  let dashboardName = localStorage.getItem("dashboard");
  const dashboardString = JSON.stringify(dashboardName);

  axios
    .get(
      process.env.VUE_APP_API_URL +
        "public/api/v1/dashboards?name=" +
        encodeURIComponent(dashboardString),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "X-TenantID": tenantId,
        },
      }
    )
    .then((response) => {
      if (response.data.statusCode === 200) {
        const dashboardData = response.data.data.dashboards[0];
        this.chartType = dashboardData.Charts; // Current dashboard charts
        this.selectedChartIds = this.chartType.map((chart) => chart.ID)

        // Always use the full chart type list
        this.chartTypesNames = [
        ...this.allChartTypes.map((chart) => chart.Name),
          "+ Create New Chart"
        ];

        // Initialize selections
        this.chartSelections = Array(this.chartType.length).fill(null);
        if (this.chartType.length > 0) {
          this.chartSelections[0] = this.chartType[0]?.Name;
          this.chartSelections[1] = this.chartType[1]?.Name;
          this.chartSelections[2] = this.chartType[2]?.Name;
          this.chartSelections[3] = this.chartType[3]?.Name;
          this.chartSelections[4] = this.chartType[4]?.Name;
          this.chartSelections[5] = this.chartType[5]?.Name;
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching dashboard data:", error);
    })
    .finally(() => {
      this.showLoader = false;
    });
},
    async fetchChartsData(index = null, selectedChart) {
      if (index !== null) {
        this.clearMessages(index);
        this.loading[index] = true;

        try {
          if (selectedChart) {
          var chart = selectedChart;
        }
        else {
          var chart = this.chartTypes[index];
        }
          const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

          if (response.data.statusCode === 200) {
            const rows = response.data.data.TableData.rows;
            if(rows) {
              this.tableData[index] = rows;
              this.dataLoaded[index] = true;
              this.loading[index] = false;
              this.renderChart(chart, index, "chart_");
            }
            else {
              this.loading[index] = false;
              this.noData[i] = "no data available to plot chart."
            }
          }
        } catch (error) {
          this.handleError(index, error);
        }
      } else {
        this.clearAllMessages();
        this.loading = this.chartTypes.map(() => true);

        for (let i = 0; i < this.chartTypes.length; i++) {
          const chart = this.chartTypes[i];
          try {
            const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

            if (response.data.statusCode === 200) {
            const rows =  response.data.data.TableData.rows;
            if(rows) {
              this.tableData[i] = rows;
              this.dataLoaded[i] = true;
              this.loading[i] = false;
              this.renderChart(chart, i, "chart_");
            }
            else {
              this.loading[i] = false;
              this.noData[i] = "no data available to plot chart."
            }
            }
          } catch (error) {
            this.handleError(i, error);
          }
        }
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.renderChart(this.chartTypes[index], index, "modal_chart_");
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },

    renderChart(chart, index, refPrefix) {
      this.$nextTick(() => {
        const chartRef = this.$refs[refPrefix + index];
        if (!chartRef || !chartRef.length) return;

        const chartDiv = chartRef[0];
        if (!chartDiv) return;

        const traces = this.generateTraces(chart, index);
        const layout = this.generateLayout(chart, index);
        const config = this.generateConfig(index);

        Plotly.newPlot(chartDiv, traces, layout, config);
      });
    },

    generateTraces(chart, index) {
      const traceType = this.getTraceType(chart);
      const traceMode = this.getTraceMode(chart);
      const scatterMarkerSizes = 8;
      const yColumns = chart.YAxisColumn.split(",");
      this.selectedYAxisKeys[index] = yColumns;

      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop();
        this.selectedColorGroupKeys[index] = chart.GroupColumn;
        this.selectedXAxisKeys[index] = chart.XAxisColumn;
        this.selectedZAxisKeys[index] = chart.ZAxisColumn;

        const colorGroupData = this.tableData[index].map(item => item[chart.GroupColumn]);
        const uniqueValues = [...new Set(colorGroupData.filter(item => item !== undefined && item !== null))];

        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        uniqueValues.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        this.tableData[index].forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKeys[index]];

          if (uniqueValues.includes(colorGroupValue)) {
            const yAxisRowData = this.selectedYAxisKeys[index].map(key => row[key]);
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            const xAxisRowData = row[this.selectedXAxisKeys[index]];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            const zAxisRowData = row[this.selectedZAxisKeys[index]];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });

        return Object.keys(groupedXAxisData).map((key) => {
          const xData = groupedXAxisData[key];
          let yData = groupedYAxisData[key];
          const zData = groupedZAxisData[key];
          this.markerSizes = [...zData];

          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]);

          const markerSize = (chart.ChartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: key,
            marker: {
              size: markerSize
            },
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          return trace;
        }).filter(trace => trace !== null);
      } else {
        const xData = this.tableData[index].map(item => item[chart.XAxisColumn]);
        const yData = yColumns.map(col => this.tableData[index].map(item => item[col]));
        const zData = this.tableData[index].map(item => item[chart.ZAxisColumn]);

        this.markerSizes = [...zData];

        const markerSize = (chart.ChartType === "Scatter Plot")
          ? (scatterMarkerSizes)
          : (this.markerSizes);

        return yData.map((y, i) => {
          const trace = {
            x: xData,
            y: y,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: yColumns[i],
            marker: {
              size: markerSize
            },
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          return trace;
        });
      }
    },

    generateLayout(chart, index) {
      const yColumns = chart.YAxisColumn.split(",");
      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop(); // Remove the last Y-axis column if GroupColumn is present
      }
      return {
        // title: `${chart.Name} <br> (${chart.ChartType})`,
        xaxis: { title: chart.XAxisColumn },
        yaxis: { title: yColumns.join(", ") },
        legend: {
          title: {
            text: this.selectedColorGroupKeys[index] ? this.selectedColorGroupKeys[index] : ''
          }
        },
        updatemenus: [
          {
            buttons: [
              {
                label: "Download CSV",
                method: "downloadCsv",
              },
            ],
          },
        ],
      };
    },

    generateConfig(index) {
      return {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
        modeBarButtonsToAdd: [
          {
            name: 'Download CSV',
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.handleDownloadCSV.bind(this, index)
          }
        ]
      };
    },

    getTraceType(chart) {
      switch (chart.ChartType) {
        case "Line Chart": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },

    getTraceMode(chart) {
      if (chart.ChartType === "Bubble") return "markers";
      if (chart.ChartType === "Scatter Plot") return "markers";
      return undefined;
    },

    handleDownloadCSV(index) {
      const chartData = this.tableData[index];
      const csvContent = this.convertToCsv(chartData);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const fileName = `chart_data_${index}.csv`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      for (const row of data) {
        const values = headers.map(header => JSON.stringify(row[header], (key, value) => value === null ? "" : value));
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    },

    clearMessages(index) {
      this.timeoutMessage[index] = "";
      this.apiError[index] = "";
    },

    clearAllMessages() {
      this.timeoutMessage = this.chartTypes.map(() => "");
      this.apiError = this.chartTypes.map(() => "");
    },

    handleError(index, error) {
      this.loading[index] = false;
      this.timeoutMessage[index] = error.message || "An error occurred";
      if (error.response && error.response.status) {
        this.apiError[index] = "Api Error";
      }
    },
  },

  mounted() {
    window.addEventListener('storage', this.handleStorageChange);
    this.getSavedCharts();
    this.fetchData();
    this.fetchChartsData();
  },
};
</script>