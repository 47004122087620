<template>
  <div class="main-bg">
    <v-img :src="mainBg" alt="main-bg"  class="full-height-image"></v-img>
  </div>
</template>

<script>
export default {
    name: "MainAuth",
    data() {
        return {
            mainBg: require("../../../assets/Images/Auth/auth-img.jpg"),
        }
    }
}
</script>