<template>
    <div class="chart-table-wrap px-0 pb-0">
      <div>
    <!-- <div class="pl-2">
      <v-switch v-if="dlComposition && dlComposition.length > 0 && !loading" v-model="toggleFraction" color="info"
        :label="toggleFraction ? 'Mole %' : 'Mass %'" @change="toggleFractionFunction"></v-switch>
    </div> -->
    <div class="chart-wrap">

    <v-row>
      <v-col cols="12" md="6">
        <GasCompComponentChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
      <v-col cols="12" md="6">
        <GasCompPressureChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
    </v-row>
    </div>
    <!-- <div v-if="dlComposition && dlComposition.length > 0 && !loading"> -->

    <div class="table-wrap border-0" v-if="dlComposition && dlComposition.length > 0 && !loading">
      <div class="table-title-div">
        <p class="table-title">Liberated Gas Compositions</p>
        <div class="csv-btn" @click="downloadTableCSV">
          <img class="pointer" :src="documentDownload" alt="" />
          <div>
            Download CSV
          </div>
        </div>
      </div>
      <table class="data-table">
        <tr class="table-sub-header">
          <td class="d-flex align-center img-td">
                <span class="mr-2">Pressure (psia) </span>
                <v-img :src="arrow_right" alt="Arrow" width="20" height="20" />
              </td>
          <td class="text-left" v-for="component in uniqueStepPressure" :key="component">
            {{ component }}
          </td>
        </tr>
        <tr class="component-td">
          <td class="d-flex align-center img-td">
                <span class="mr-2">Component</span>
                <v-img :src="arrow_down" alt="Arrow" width="20" height="20" />
              </td>
          <td class="text-left" v-for="component in uniqueStepPressure" :key="component">
            {{ toggleFraction ? 'Mole%' : 'Mass%' }}
          </td>
        </tr>
        <tbody>
          <tr  v-for="component in uniqueFluidComponents" :key="component">
            <td class="text-left">{{ component }}</td>
            <td class="text-left" v-for="(entry) in dlComposition.filter(entry => entry.fluidComponentReference === component)"
              :key="entry.stepPressure">
              {{ toggleFraction
          ? formatFraction(entry.vapor_MoleFraction)
          : formatFraction(entry.vapor_MassFraction) }}
            </td>
          </tr>
          <tr>
            <td class="text-left">Total</td>
            <td class="text-left" v-for="pressure in uniqueStepPressure" :key="pressure">
              {{ calculateSum(pressure) }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="liberated-bottom-table">
        <div class="table-title-div">
          <p class="table-title">Calculated properties of Total Sample @ Standard Conditions</p>
        </div>
        <table class="data-table data-table-liberated">
          <tr class="main-header">
            <td class="main-header-td">
              <span class="mr-2">MW (g/mol)</span>
            </td>
            <td v-for="component in MW" :key="component">
              {{ component }}
            </td>
          </tr>
          <tr class="main-header">
            <td class="main-header-td">
              <span class="mr-2">Gravity (Air=1)</span>
            </td>
            <td v-for="component in Gravity" :key="component">
              {{ component }}
            </td>
          </tr>
        </table>
      </div>

      <div class="liberated-bottom-table">
        <div class="table-title-div">
          <p class="table-title">Calculated properties of C7 @ Standard Conditions</p>
        </div>
        <table class="data-table data-table-liberated">
          <tr class="main-header">
            <td class="main-header-td">
              <span class="mr-2">MW (g/mol)</span>
            </td>
            <td v-for="component in MW" :key="component">
              {{ component }}
            </td>
          </tr>
          <tr class="main-header">
            <td class="main-header-td">
              <span class="mr-2">Density (g/cc)</span>
            </td>
            <td v-for="component in Density" :key="component">
              {{ component }}
            </td>
          </tr>
        </table>
      </div> -->
    </div>
    <div v-else-if="!loading && !dlComposition" class="text-center pt-15">
      <h4>Table data is not available.</h4>
    </div>
    <div v-else-if="loading">
      <screen-loader></screen-loader>
    </div>
  </div>
  </div>
</template>

<script>
import GasCompComponentChart from "./Charts/GasCompComponentChart.vue";
import GasCompPressureChart from "./Charts/GasCompPressureChart.vue";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import axios from "axios";

export default {
  name: "GasCompData",
  components: {
    GasCompComponentChart,
    GasCompPressureChart,
    ScreenLoader,
  },
  data() {
    return {
      arrow_right: require("../../../assets/Images/Assets/arrow-right.png"),
      arrow_down: require("../../../assets/Images/Assets/arrow-down.png"),
      dlComposition: [],
      tenantId: localStorage.getItem("tenantId"),
      loading: true,
      toggleFraction: true,
      documentDownload: require("../../../assets/Images/csv.svg"),
    };
  },
  computed: {
    // WM() {
    //   if (!this.dlComposition) {
    //     return;
    //   }
    //   return [...new Set(this.dlComposition.map(entry => entry.MW))];
    // },
    // Gravity() {
    //   if (!this.dlComposition) {
    //     return;
    //   }
    //   return [...new Set(this.dlComposition.map(entry => entry.Gravity))];
    // },
    // Density() {
    //   if (!this.dlComposition) {
    //     return;
    //   }
    //   return [...new Set(this.dlComposition.map(entry => entry.Density))];
    // },
    uniqueFluidComponents() {
      if (!this.dlComposition) {
        return;
      }
      return [...new Set(this.dlComposition.map(entry => entry.fluidComponentReference))];
    },
    uniqueStepPressure() {
      if (!this.dlComposition) {
        return;
      }
      return [...new Set(this.dlComposition.map(entry => entry.stepPressure))];
    },
    getChartData() {
      if (!this.dlComposition) {
        return;
      }
      // Filter dlComposition data based on toggleFraction
      if (this.toggleFraction) {
        // Filter for Mole%
        return this.dlComposition.map(entry => ({
          fluidComponentReference: entry.fluidComponentReference,
          stepPressure: entry.stepPressure,
          vapor_MoleFraction: entry.vapor_MoleFraction
        }));
      } else {
        // Filter for Mass%
        return this.dlComposition.map(entry => ({
          fluidComponentReference: entry.fluidComponentReference,
          stepPressure: entry.stepPressure,
          vapor_MassFraction: entry.vapor_MassFraction
        }));
      }
    }
  },
  methods: {
    calculateSum(stepPressure) {
      if (!this.dlComposition) {
        return 0;
      }
      const filteredEntries = this.dlComposition.filter(
        (entry) => entry.stepPressure === stepPressure
      );
      return filteredEntries.reduce((sum, entry) => {
        return (
          sum +
          (this.toggleFraction
            ? parseFloat(entry.vapor_MoleFraction || 0)
            : parseFloat(entry.vapor_MassFraction || 0))
        );
      }, 0).toFixed(3);
    },
    toggleFractionFunction() {
      this.dlComposition = [];
      this.getTableData();
    },
    formatFraction(value) {
    if (value === null || value === undefined) {
      return "-";
    }
    return value.toFixed(3);
  },
    getTableData() {
      var sampleId = localStorage.getItem("sampleId");
      var dlId = localStorage.getItem("testId");
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/dltests/${dlId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dlComposition = response.data.data.dltest.dlComposition;
            if (!this.dlComposition) {
              return;
            }
            else {
              // const sortByKeys = response.data.data.dltest.dlComposition.map(item => item[sortByArray])[0].flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));
              const sortByArray = this.toggleFraction ? 'sortByMole' : 'sortByMass';
              let sortByKeys = [];
              if (response.data.data.dltest.dlComposition[0][sortByArray] && response.data.data.dltest.dlComposition[0][sortByArray].length > 0) {
                sortByKeys = response.data.data.dltest.dlComposition.map(item => item[sortByArray]).flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));
              } else {
                // Fallback to using serverItems for headers and display names
                // Filter out keys that contain _UOM
                sortByKeys = Object.keys(this.serverItems || {}).filter(
                  (key) => !key.includes("_UOM")
                ).map(
                  (key) => key.charAt(0).toLowerCase() + key.slice(1)
                );
              }

              const availableKeys = new Set(
                sortByKeys.filter((key) =>
                  this.dlComposition.some((item) => key in item)
                )
              );

              const allKeys = sortByKeys.filter((key) => availableKeys.has(key));
              const allUniqueKeys = Array.from(allKeys);

              const displayNames = [];

              Promise.all(
                allUniqueKeys.map(async (key) => {
                  const capitalizedKey =
                    key.charAt(0).toUpperCase() + key.slice(1);
                  const displayName = await this.$store.dispatch(
                    "metaData/getDisplayName",
                    { tableName: "DLComposition", fieldName: capitalizedKey }
                  );
                  displayNames.push(displayName);
                })
              );
              this.loading = false;

              return response.data.data.dltest;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadTableCSV() {
      if (this.dlComposition && this.dlComposition.length > 0) {
        const fileName = `liberated_gas_compositions.csv`;

        // Construct CSV headers
        const headerRow1 = ['Pressure (psia)', ...this.uniqueStepPressure];
        const headerRow2 = ['Component', ...this.uniqueStepPressure.map(() => (this.toggleFraction ? 'Mole%' : 'Mass%'))];

        // Construct CSV rows
        const csvRows = this.uniqueFluidComponents.map(component => {
          const row = [component];
          this.uniqueStepPressure.forEach(pressure => {
            const entry = this.dlComposition.find(e => e.fluidComponentReference === component && e.stepPressure === pressure);
            const value = this.toggleFraction ? entry?.vapor_MoleFraction : entry?.vapor_MassFraction;
            row.push(this.formatFraction(value));
          });
          return row;
        });

        // Combine headers and rows into CSV content
        const csvContent = [
          headerRow1.join(','),
          headerRow2.join(','),
          ...csvRows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger CSV download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Your browser does not support downloading files programmatically. Please try a different browser.');
        }
      } else {
        console.error('No data available in the table to download.');
      }
    },
  },
  mounted() {
    this.getTableData();
  },
};
</script>