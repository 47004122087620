<template>
  <div class="composition-page">
    <screen-loader v-if="showLoaders"></screen-loader>
    <div v-else-if="timeoutMessage != ''" class="tabs-no-test-div timeout">
      <div class="text-center">
        {{ timeoutMessage }}
        <v-icon
          flat
          @click="loadItems()"
          color="blue-darken-3"
          icon="mdi-reload"
          size="large"
        ></v-icon>
      </div>
    </div>
    <v-card-text
      class="chart-table-wrap px-0 pb-0"
      v-else-if="hasRelevantData && serverItems.length > 0"
    >
      <!-- <div class="pl-2">
        <v-switch
          v-if="serverItems.length > 0 && showLoaders == false"
          v-model="toggleFraction"
          color="info"
          :label="toggleFraction ? 'Mass %' : 'Mole %'"
          @change="toggleFractionFunction"
        ></v-switch>
      </div> -->
      <div class="composition-properties-wrap">
        <div class="table-wrap mt-0">
          <div class="table-title-div" v-if="serverItems.length > 0">
            <p class="table-title">Composition Properties</p>
            <div class="csv-btn" @click="downloadTableCSV">
              <img class="pointer" :src="documentDownload" alt="" />
              <div>Download CSV</div>
            </div>
          </div>
          <v-data-table
            :items="serverItems"
            :loading="loading"
            virtual-items
            fixed-header
          >
            <!-- Main Header -->
            <template v-slot:headers>
              <tr class="main-header">
                <th class="sticky-header" style="text-align: left !important">
                  Properties
                </th>
                <th class="sticky-header">Flashed Gas</th>
                <th class="sticky-header">Flashed Liquid</th>
                <th class="sticky-header">Overall</th>
              </tr>
              <!-- Sub Header -->
              <tr class="sub-header">
                <th colspan="8" style="text-align: left !important">Mole %</th>
              </tr>
            </template>

            <!-- Table Body -->
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td style="text-align: left !important">
                  {{ item.fluidComponentReference }}
                </td>
                <td>
                  {{
                    displayValue("vapor_MoleFraction", item.vapor_MoleFraction)
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      "liquid_MoleFraction",
                      item.liquid_MoleFraction
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      "overall_MoleFraction",
                      item.overall_MoleFraction
                    )
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :items="serverItems"
            :loading="loading"
            virtual-items
            fixed-header
          >
            <!-- Main Header -->
            <template v-slot:headers>
              <!-- Sub Header -->
              <tr class="sub-header">
                <th colspan="8" style="text-align: left !important">Mass %</th>
              </tr>
            </template>

            <!-- Table Body -->
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td style="text-align: left !important">
                  {{ item.fluidComponentReference }}
                </td>
                <td>
                  {{
                    displayValue("vapor_MassFraction", item.vapor_MassFraction)
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      "liquid_MassFraction",
                      item.liquid_MassFraction
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      "overall_MassFraction",
                      item.overall_MassFraction
                    )
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :items="serverItems"
            :loading="loading"
            virtual-items
            fixed-header
          >
            <!-- Main Header -->
            <template v-slot:headers>
              <!-- Sub Header -->
              <tr class="sub-header">
                <th colspan="8" style="text-align: left !important">
                  MolarMass %
                </th>
              </tr>
            </template>

            <!-- Table Body -->
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td style="text-align: left !important">
                  {{ item.fluidComponentReference }}
                </td>
                <td>
                  {{ displayValue("vapor_MW", item.vapor_MW) }}
                </td>
                <td>
                  {{ displayValue("liquid_MW", item.liquid_MW) }}
                </td>
                <td>
                  {{ displayValue("overall_MW", item.overall_MW) }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :items="serverItems"
            :loading="loading"
            virtual-items
            fixed-header
          >
            <!-- Main Header -->
            <template v-slot:headers>
              <!-- Sub Header -->
              <tr class="sub-header">
                <th colspan="8" style="text-align: left !important">
                  Density (g/cc)
                </th>
              </tr>
            </template>

            <!-- Table Body -->
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td style="text-align: left !important">
                  {{ item.fluidComponentReference }}
                </td>
                <td>
                  {{ displayValue("vapor_Density", item.vapor_Density) }}
                </td>
                <td>
                  {{ displayValue("liquid_Density", item.liquid_Density) }}
                </td>
                <td>
                  {{ displayValue("overall_Density", item.overall_Density) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="table-wrap single-stage-table-wrap mt-0">
          <div class="table-title-div" v-if="serverItems.length > 0">
            <p class="table-title">Single Stage Flash Data</p>
            <div class="csv-btn" @click="downloadTableCSV">
              <img class="pointer" :src="documentDownload" alt="" />
              <div>Download CSV</div>
            </div>
          </div>
          <v-data-table
            :items="serverItems"
            :loading="loading"
            virtual-items
            fixed-header
          >
            <!-- Main Header -->
            <template v-slot:headers>
              <tr class="main-header">
                <th class="sticky-header"></th>
                <th class="sticky-header">Original STO</th>
              </tr>
              <!-- Sub Header -->
            </template>

            <!-- Table Body -->
            <template v-slot:body>
              <tr>
                <td style="text-align: left !important">GOR (variable)</td>
                <td>
                  {{ flashGOR.toFixed(3) }}
                </td>
              </tr>
              <tr>
                <td style="text-align: left !important">STO Density (g/cc)</td>
                <td>
                  {{
                    LiquidDensity.toFixed(3)
                  }}
                </td>
              </tr>
              <tr>
                <td style="text-align: left !important">STO API Gravity</td>
                <td>
                  {{
                      oilAPIGRavity.toFixed(3)
                  }}
                </td>
              </tr>
              <tr>
                <td style="text-align: left !important">STO MW</td>
                <td>
                  {{ oilMolecularWeight.toFixed(3) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card-text>
    <div v-else class="tabs-no-test-div">
      <h4>No data available.</h4>
    </div>
  </div>
</template>

<script>
import ScreenLoader from "../../Loaders/ScreenLoader.vue";
import { getDisplayName } from "../../../../utils/cache";
import api from "../../../../axiosInterceptor";
export default {
  components: { ScreenLoader },
  name: "CompositionPropertiesTab",
  data: () => ({
    showLoaders: true,
    documentDownload: require("../../../../assets/Images/csv.svg"),
    itemsPerPage: 0,
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    testID: "",
    testName: "",
    sampleID: "",
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    toggleFraction: true,
    timeoutMessage: "",
    flashGOR: "",
    LiquidDensity: "",
    oilAPIGRavity: "",
    oilMolecularWeight: "",
  }),
  computed: {
    hasRelevantData() {
      if (this.toggleFraction) {
        // Check for Mass data
        return this.serverItems.some(
          (item) => item.overall_MassFraction !== undefined
        );
      } else {
        // Check for Mole data
        return this.serverItems.some(
          (item) => item.overall_MoleFraction !== undefined
        );
      }
    },
  },
  methods: {
    toggleFractionFunction() {
      this.serverItems = [];
      if (this.loading && this.serverItems.length === 0) {
        this.showLoaders = true;
      }

      this.loadItems();
    },
    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var compositionId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;

      try {
        let response = await api.get(
          `public/api/v1/samples/${sampleId}/composition/${compositionId}`
        );

        this.serverItems =
          response.data.data.composition &&
          response.data.data.composition.compositionProperties
            ? response.data.data.composition.compositionProperties
            : [];

            this.flashGOR = response.data.data.composition.flashGOR;
            this.LiquidDensity = response.data.data.composition.liquidDensity;
            this.oilAPIGRavity = response.data.data.composition.oilAPIGravity;
            this.oilMolecularWeight = response.data.data.composition.oilMolecularWeight;
        this.totalItems = response.data.data.composition.length;
        this.testID = response.data.data.composition.ID;
        this.testName = response.data.data.composition.name;
        this.sampleID = response.data.data.composition.sampleID;
        this.saturationPressure =
          response.data.data.composition.saturationPressure;
        this.saturationPressure_UOM =
          response.data.data.composition.saturationPressure_UOM;
        this.testTemperature = response.data.data.composition.testTemperature;
        this.testTemperature_UOM =
          response.data.data.composition.testTemperature_UOM;
        this.sendDataToParent();

        const sortByKeys = response.data.data.composition.sortBy.map(
          (key) => key.charAt(0).toLowerCase() + key.slice(1)
        );

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );

        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        // Filter out "H2" from serverItems
        this.serverItems = this.serverItems.filter(
          (item) => item.fluidComponentReference !== "H2"
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(
          allUniqueKeys.map(async (key) => {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const { displayName, digits } = await getDisplayName(
              "Composition",
              capitalizedKey
            );
            displayNames.push(displayName);
            digitsArray.push(digits);
          })
        );

        // Update the headers array to use display names
        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index]; // Use display name instead of key
          let unit = uom ? uom[key + "_UOM"] : "-";
          // Check if unit is empty or equals "data-not-available", then set it to "-"
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits,
          };
        });

        return response.data.data.composition;
      } catch (error) {
        this.timeoutMessage = error;
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    displayValue(key, value) {
      const header = this.headers.find((header) => header.key === key);
      const digits = header ? header.digits : 0; // Get digits from header

      if (value !== null && value !== undefined) {
        // Format the value with the specified number of decimal places
        return digits > 0 ? value.toFixed(digits) : value;
      } else {
        return "-";
      }
    },
    sendDataToParent() {
      this.$emit("data-updated", {
        testID: this.testID,
        testName: this.testName,
        sampleID: this.sampleID,
        saturationPressure: this.saturationPressure,
        saturationPressure_UOM: this.saturationPressure_UOM,
        testTemperature: this.testTemperature,
        testTemperature_UOM: this.testTemperature_UOM,
      });
    },
    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `composition_properties_table.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>