<template>
  <div>
    <ScreenLoader class="predefined-filter-data" v-if="showLoader" />
    <div v-if="!showLoader" class="carousel-wrapper">
      <!-- Click event to toggle carousel visibility -->
      <div class="recent-activity-arrow">
        <p class="recent-activity">Pre-Defined Filters</p>
      </div>
      <!-- Carousel for predefined filters -->
      <v-carousel v-if="filterSummaries.length > 0" class="predefined-filter-carousel" v-model="carouselIndex" hide-delimiters height="200px">
        <template #prev>
          <v-btn icon :disabled="carouselIndex === 0" @click="carouselIndex--">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <template #next>
          <v-btn
            icon
            :disabled="
              carouselIndex === Math.ceil(filterSummaries.length / chunkSize) - 1
            "
            @click="carouselIndex++"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>

        <v-carousel-item
          v-for="(pair, index) in chunkArray(filterSummaries, chunkSize)"
          :key="index"
        >
          <v-row no-gutters>
            <v-col class="pl-2" v-for="filter in pair" :key="filter.ID" :cols="Math.floor(12 / chunkSize)">
              <v-sheet
                class="sheet px-0"
                
                @click="getFilterDetailTopage(filter)"
              >
                <v-card link class="v-card-asset" :class="{ 'bg-active-filter': selectedFilterId === filter.ID }">
                  <v-card-title class="assets_name">
                    Name: {{ filter.name }}
                  </v-card-title>
                  <v-card-text class="assets-data">
                    <!-- Assets -->
                    <div
                      class="test-fields-container container-top"
                      style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);"
                    >
                      <div class="test-field-entry">
                        Assets
                        <span>{{
                          filter.assets ? filter.assets.length : 0
                        }}</span>
                      </div>
                      <div class="test-field-entry">
                        Fields
                        <span>{{
                          filter.fields ? filter.fields.length : 0
                        }}</span>
                      </div>
                      <div class="test-field-entry">
                        Wells
                        <span>{{
                          filter.wells ? filter.wells.length : 0
                        }}</span>
                      </div>
                      <div class="test-field-entry">
                        Samples
                        <span>{{
                          filter.samples ? filter.samples.length : 0
                        }}</span>
                      </div>
                    </div>
                    <!-- Test fields -->
                    <div class="test-fields-container pb-0 mb-0">
                      <div class="test-field-entry">
                        <p class="test-name">Compositions</p>
                        <span>{{
                          filter.compositions ? filter.compositions : 0
                        }}</span>
                      </div>
                      <div class="test-field-entry">
                        <p class="test-name">CCE Tests</p>
                        <span>{{ filter.cceTests ? filter.cceTests : 0 }}</span>
                      </div>
                      <div class="test-field-entry">
                        <p class="test-name">DLTests</p>
                        <span>{{ filter.dlTests ? filter.dlTests : 0 }}</span>
                      </div>
                      <div class="test-field-entry">
                        <p class="test-name">CVD Test</p>
                        <span>{{ filter.cvdTests ? filter.cvdTests : 0 }}</span>
                      </div>
                      <div class="test-field-entry">
                        <p class="test-name">Separator</p>
                        <span>{{
                          filter.separatorTests ? filter.separatorTests : 0
                        }}</span>
                      </div>
                      <div class="test-field-entry">
                        <p class="test-name">Viscosity Tests</p>
                        <span>{{
                          filter.viscosityTests ? filter.viscosityTests : 0
                        }}</span>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>

      <!-- No filters found message -->
      <div v-if="filterSummaries.length === 0" class="mb-3">
        <p>No filters found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../axiosInterceptor";
import ScreenLoader from "../layout/Loaders/ScreenLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "PreDefinedFilters",
  components: {
    ScreenLoader,
  },
  data: () => ({
    filterSummaries: [],
    showLoader: false,
    timeoutMessage: "",
    carouselIndex: 0,
    chunkSize: 4, // Default chunk size
    selectedFilterId: null,
  }),
  props: {
    drawerValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    groupedFilters() {
      // Group the filters into pairs
      return this.filterSummaries.reduce((result, filter, index) => {
        if (index % 4 === 0) {
          result.push([filter]);
        } else {
          result[result.length - 1].push(filter);
        }
        return result;
      }, []);
    },
  },
  methods: {
     handleResize() {
      this.chunkSize = this.calculateChunkSize();
    },
    chunkArray(array, size) {
      const chunks = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    },
    // chunkArray(array) {
    //   return this.chunkArray(array, this.chunkSize);
    // },
    calculateChunkSize() {
      if (window.innerWidth >= 1300) return 4; // Large screens
      if(window.innerWidth >= 1100) return 3; // Large screens
      if(window.innerWidth >= 850) return 2;
      if (window.innerWidth >= 320) return 1; // Tablets
    },
    getFilterDetailTopage(filter) {
      this.selectedFilterId = filter.ID;
      localStorage.setItem("lastFilterId", filter.ID); // Set selected filter id into local storage as lastFilterId
      this.$emit("getFilterDetailTopage", filter);
    },
    callApi() {
      this.getFilterSummaries();
    },
    getFilterSummaries() {
      this.showLoader = true;
      this.filterSummaries = [];
      this.timeoutMessage = "";

      api
        .get("public/api/v1/filters/summary")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.filterSummaries = response.data.data.filterWithSummary.filter(
              (summary) => summary.name
            );
            this.$emit('showFilterDiv', this.filterSummaries.length > 0 ? true : false);
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
  },
  watch: {
    getSelectedTenant: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getFilterSummaries();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.chunkSize = this.calculateChunkSize();
    window.addEventListener("resize", this.handleResize);
    this.getFilterSummaries();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>