<template>
  <div class="exploration-table">
    <div class="table-top-content">
      <div class="d-flex align-center">
        <p class="recent-activity-wrap">Fluids Characterization Data</p>
        <div class="applied-filters" v-if="filterDetails">
          <span v-if="filterDetails && filterDetails.asset && filterDetails.asset.length > 0">Asset: {{
            filterDetails.asset.join(', ') }}</span>
          <span v-if="filterDetails && filterDetails.field && filterDetails.field.length > 0">Field: {{
            filterDetails.field.join(', ') }}</span>
          <span v-if="filterDetails && filterDetails.well && filterDetails.well.length > 0">Well: {{
            filterDetails.well.join(', ') }}</span>
          <span v-if="filterDetails && filterDetails.reservoir && filterDetails.reservoir.length > 0">Reservoir: {{
            filterDetails.reservoir.join(', ') }}</span>
          <span v-if="filterDetails && filterDetails.sampleKind && filterDetails.sampleKind.length > 0">Sample kind: {{
            filterDetails.sampleKind.join(', ') }}</span>
          <span v-if="filterDetails && filterDetails.selectedTests && filterDetails.selectedTests.length > 0">Test: {{
            filterDetails.selectedTests.join(', ') }}</span>
          <div class="reset-icon-div" v-if="filterDetails" @click="resetFilters">
            <v-img :src="reset_icon" alt="reset-icon"></v-img>
            <span>Reset</span>
          </div>
        </div>
      </div>
      <div v-if="filterData?.samples.length > 0">
        <div v-if="loadingCsv" class="csv-btn">
          <img class="pointer" :src="documentDownload" alt="" />
          <div>Exporting..</div>
        </div>
        <div v-else class="test-pdf-div">
          <v-btn class="pdf-btn">
            <div class="sample-test-div">
              <img :src="documentDownload" alt="pdf icon" />
            </div>
            <span class="pdf-text">Download CVS</span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="serverItems"
      :items-per-page-options="pageOptions" :items-length="totalItems" :loading="loading" item-value="name"
      @update:options="handlePageChange" fixed-header :height="tableHeight">
      <template v-slot:loader>
        <v-skeleton-loader type="table-row-divider" :loading="loading" :types="{
          'table-row-divider': {
            height: 'calc(100vh - 425px)',
            rows: 5
          }
        }">
        </v-skeleton-loader>
      </template>
      <template v-slot:headers>
        <tr>
          <th v-for="header in headers" :key="header.value" class="text-left search-dropdown-menu" :style="{ backgroundColor: searchDropdowns[header.key] ? 'black !important' : 'transparent' }"
>

            <v-menu v-model="searchDropdowns[header.key]" :close-on-content-click="false"
              v-model:return-value="selectedLocations[header.key]" transition="scale-transition">
              <template v-slot:activator="{ props }">
                <span v-bind="props" :id="`menu-activator-${header.key}`"></span>
              </template>
              <v-list style="min-width: 182px;
                max-width: 200px;
                border-radius: 8px !important;
                height: 244px !important;
                padding:8px;
                max-height: 244px;
                overflow-y: auto;
                position: absolute;
                top: 3.25em;
                left: -4.25em;
                transform: translateY(-1.25em);
              ">
                <!-- Search Field -->
                <v-text-field class="search-table"  v-model="searchQuery[header.key]" :label="`Search ${header.title}`" single-line hide-details>
                  <template v-slot:prepend>
                    <v-img :src="magnify_black" class="ml-2" width="20" height="20"></v-img>
                  </template>
                </v-text-field>
                  

                <!-- Skeleton Loader -->
                <v-skeleton-loader v-if="searchLoading" class="pa-2" />

                <!-- No Data Found -->
                <v-list-item v-else-if="filteredItems(header.key).length === 0" disabled>
                  <v-list-item-title style="font-size: 14px !important;">No data found</v-list-item-title>
                </v-list-item>

                <v-list-item class="list-items" style="margin: 0 !important; padding: 0 10px !important; height: 34px !important;" v-for="location in filteredItems(header.key)"
                  :key="location.name">
                  <!-- <v-checkbox style="height: 34px !important;" :label="location.name"
                    :model-value="location.isSelected"
                    @change="toggleSelection(header.key, location.name, location.isSelected)" @click.stop
                    hide-details /> -->
                    <div class="data-list-content">
                      <input type="checkbox"
                        :model-value="location.isSelected"
                        @change="toggleSelection(header.key, location.name, location.isSelected)" @click.stop />
                      <span>{{ location.name }}</span>
                    </div>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="d-flex align-center justify-space-between">
              {{ header.title }}
               <div class="close-icon" v-if="searchDropdowns[header.key]" @click="searchDropdowns[header.key] = false">
                  <v-img :src="close_icon" alt="close-icon"></v-img>
                </div>
                <div v-else class="search-icon" @click="showSearchDropdown(header.key)" :id="`menu-activator-${header.key}`">
                  <v-img :src="magnify" alt="reset-icon"></v-img>
                </div>
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <template v-if="item.location?.asset">
              <span class="clickable-cell no-bg" @click="navigateToAsset(item.location.asset, item.ID)">{{
                item.location.asset
                }}</span>
            </template>
          </td>
          <td>
            <template v-if="item.location?.field">
              <span class="clickable-cell no-bg"
                @click="navigateToField(item.location.asset, item.location.field, item.ID)">{{ item.location.field
                }}</span>
            </template>
          </td>
          <td>
            <template v-if="item.location?.well">
              <span class="clickable-cell no-bg"
                @click="navigateToWell(item.location.asset, item.location.field, item.location.well, item.ID)">{{
                  item.location.well }}</span>
            </template>
          </td>
          <td>{{ item.location?.reservoir || '' }}</td>
          <td>{{ item.sampleKind || "" }}</td>
          <td>
            <template v-if="item.IsTestAvailable">
              <div class="test-links">
                <span v-if="item.composition" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'composition', item.composition.ID, item.ID)">{{
                    item.composition.name || 'Composition' }}</span>
                <span v-if="item.ccetests?.length" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'ccetest', item.ccetests[0].ID, item.ID)">{{
                    item.ccetests[0].name || 'CCE Test' }}</span>
                <span v-if="item.dltests?.length" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'dltest', item.dltests[0].ID, item.ID)">{{
                    item.dltests[0].name || 'DL Test' }}</span>
                <span v-if="item.cvdtests?.length" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'cvdtest', item.cvdtests[0].ID, item.ID)">{{
                    item.cvdtests[0].name || 'CVD Test' }}</span>
                <span v-if="item.separatortests?.length" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'separatortest', item.separatortests[0].ID, item.ID)">{{
                    item.separatortests[0].name || 'Separator Test' }}</span>
                <span v-if="item.viscositytests?.length" class="clickable-cell mr-0"
                  @click="navigateToTest(item.location.asset, item.location.field, item.location.well, 'viscositytest', item.viscositytests[0].ID, item.ID)">{{
                    item.viscositytests[0].name || 'Viscosity Test' }}</span>
              </div>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="space-between">
          <v-btn icon :disabled="page === 1" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon :disabled="page === numberOfPages" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:no-data>
        <div class="d-flex justify-center align-center pa-4">
          No data available
        </div>
      </template>
    </v-data-table-server>
  </div>
</template>

<script>
import api from "../../axiosInterceptor";
import axios from "axios";
export default {
  name: "TableData",
  props: {
    filterData: {
      type: Object,
      default: () => ({})
    },
    filterDetails: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      reset_icon: require("../../assets/Images/reset.png"),
      magnify: require("../../assets/Images/MagnifyingGlass.png"),
      magnify_black: require("../../assets/Images/magnify-black.png"),
      close_icon: require("../../assets/Images/close-circle.png"),
      documentDownload: require("../../assets/Images/csv.svg"),
      itemsPerPage: localStorage.getItem('itemsPerPage') || 20,
      headers: [
        {
          title: 'Assets',
          sortable: false,
          key: 'assets', 
        },
        {
          title: 'Fields',
          key: 'fields',
          sortable: false,
        },
        {
          title: 'Wells',
          key: 'wells',
          sortable: false,
        },
        { title: 'Reservoir', key: 'reservoirs', sortable: false, },
        { title: 'Samples Kind', key: 'sampleKind', sortable: false, },
        { title: 'Tests', key: 'tests', sortable: false, },
      ],
      serverItems: [],
      loading: false,
      totalItems: 0,
      page: 1,
      pageOptions: [20, 50, 100, 200, 300, 400, 500],
      searchDropdowns: {
        assets: false,
        fields: false,
        wells: false,
        reservoirs: false,
      },
      selectedLocations: {
        assets: [],
        fields: [],
        wells: [],
        reservoirs: [],
        sampleKinds: [],
        tests: []
      },
      searchDropdownItems: {
        assets: [],
        fields: [],
        wells: [],
        reservoirs: [],
        sampleKind: [],
        tests: []
      },
      searchQuery: [], // New property for search input
      searchLoading: false,
      samplesCSV: [],
      loadingCsv: false
    }
  },
  computed: {
    filteredItems() {
      return (key) => {
        const items = this.searchDropdownItems[key] || [];
        const searchQuery = this.searchQuery[key]?.toLowerCase() || '';
        const selectedLocations = this.selectedLocations[key] || [];

        // Filter items based on the search query
        const matchingItems = items.filter((item) =>
          item.name?.toLowerCase().includes(searchQuery)
        );

        // Determine the list of items to show in the dropdown
        const itemsToShow = searchQuery
          ? matchingItems // Show only matching items when there's a search query
          : items; // Show all items when no search query exists

        // Highlight selected items and map the result
        const result = itemsToShow.map((item) => ({
          ...item,
          isSelected: selectedLocations.includes(item.name), // Set isSelected based on selectedLocations
        }));
        return result;
      };
    },

    tableHeight() {
      return this.showFilter ? 'calc(100vh - 474px)' : 'calc(100vh - 316px)';
    },
  },
  methods: {
    resetFilters() {
      localStorage.removeItem('lastFilterId'); // Remove lastFilterId from local storage
      this.$emit('resetFiltersEmitted'); // Emit event to parent to call sample API
    },
    toggleSelection(column, locationName, isSelected) {
      const selectedLocations = this.selectedLocations[column] || [];

      // Check if the location is already selected
      const index = selectedLocations.indexOf(locationName);

      if (isSelected) {
        // Remove the location if it is selected
        selectedLocations.splice(index, 1);
      } else {
        // Add the location if it is not selected
        selectedLocations.push(locationName);
      }

      // Update the selectedLocations array for the specific column
      this.selectedLocations[column] = selectedLocations;

      // Also update the isSelected value for the specific item in searchDropdownItems
      this.searchDropdownItems[column].forEach(item => {
        if (item.name === locationName) {
          item.isSelected = !isSelected;  // Toggle the selection state
        }
      });
      this.selectLocation();
    },
    showSearchDropdown(column) {
      this.searchDropdowns[column] = !this.searchDropdowns[column];
      if (this.searchDropdowns[column]) {
        this.fetchLocations(column);
      }
    },
    handlePageChange({ page, itemsPerPage }) {
      // Emit event to parent with pagination details
      this.$emit('update:pagination', { page, itemsPerPage });
      this.loadItems();
      localStorage.setItem('itemsPerPage', itemsPerPage); // Save the selected items per page in local storage
    },

    loadItems() {
      this.loading = this.isLoading;

      if (this.filterData && this.filterData.samples) {
        const samples = this.filterData.samples;
        const pagination = this.filterData.pagination;

        this.serverItems = samples;
        this.totalItems = pagination.TotalRecords;
        this.loading = false;
      }
    },

    navigateToAsset(asset, sampleId) {
      if (asset) {
        const encodedAsset = encodeURIComponent(asset).replace(/'/g, "%27").replace(/ /g, "-");
        this.$router.push(`/assets/${encodedAsset}`);
        localStorage.setItem('lastView', `/assets/${encodedAsset}`);
        localStorage.setItem('sampleId', sampleId);
      }
    },

    navigateToField(asset, field, sampleId) {
      if (asset && field) {
        const encodedAsset = encodeURIComponent(asset).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedField = encodeURIComponent(field).replace(/'/g, "%27").replace(/ /g, "-");
        this.$router.push(`/assets/${encodedAsset}/${encodedField}`);
        localStorage.setItem('lastView', `/assets/${encodedAsset}/${encodedField}`);
        localStorage.setItem('sampleId', sampleId);
      }
    },

    navigateToWell(asset, field, well, sampleId) {
      if (asset && field && well) {
        const encodedAsset = encodeURIComponent(asset).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedField = encodeURIComponent(field).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedWell = encodeURIComponent(well).replace(/'/g, "%27").replace(/ /g, "-");
        this.$router.push(`/assets/${encodedAsset}/${encodedField}/${encodedWell}`);
        localStorage.setItem('lastView', `/assets/${encodedAsset}/${encodedField}/${encodedWell}`);
        localStorage.setItem('sampleId', sampleId);
      }
    },

    navigateToSamples(asset, field, well, sampleId) {
      if (asset && field && well) {
        const encodedAsset = encodeURIComponent(asset).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedField = encodeURIComponent(field).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedWell = encodeURIComponent(well).replace(/'/g, "%27").replace(/ /g, "-");
        this.$router.push(`/assets/${encodedAsset}/${encodedField}/${encodedWell}`);
        localStorage.setItem('lastView', `/assets/${encodedAsset}/${encodedField}/${encodedWell}`);
        localStorage.setItem('sampleId', sampleId);
      }
    },

    navigateToTest(asset, field, well, testType, testId, sampleId) {
      if (asset && field && well && testType && testId) {
        const encodedAsset = encodeURIComponent(asset).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedField = encodeURIComponent(field).replace(/'/g, "%27").replace(/ /g, "-");
        const encodedWell = encodeURIComponent(well).replace(/'/g, "%27").replace(/ /g, "-");
        this.$router.push(`/assets/${encodedAsset}/${encodedField}/${encodedWell}/${testType}/${testId}`);
        localStorage.setItem('lastView', `/assets/${encodedAsset}/${encodedField}/${encodedWell}/${testType}/${testId}`);
        localStorage.setItem('sampleId', sampleId);
        localStorage.setItem('testId', testId); // Set the current selected test ID in local storage
      }
    },

    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.loadItems();
      }
    },

    nextPage() {
      if (this.page < this.numberOfPages) {
        this.page++;
        this.loadItems();
      }
    },

    showSearchDropdown(column) {
      this.searchDropdowns[column] = !this.searchDropdowns[column];
      if (column === 'assets') {
        this.fetchLocations('assets');
      } else if (column === 'fields') {
        this.fetchLocations('fields');
      } else if (column === 'wells') {
        this.fetchLocations('wells');
      } else if (column === 'reservoirs') {
        this.fetchLocations('reservoirs');
      } else if (column === 'sampleKind') {
        this.fetchLocations('sampleKind');
      } else if (column === 'tests') {
        this.fetchLocations('tests');
      }
    },
    async fetchLocations(columnName) {
      this.searchLoading = true;

      // Prepare the query object with the selected locations
      const query = {
        asset: this.selectedLocations.assets ? JSON.stringify(this.selectedLocations.assets) : [],  // Convert to JSON string
        field: this.selectedLocations.fields ? JSON.stringify(this.selectedLocations.fields) : [],  // Convert to JSON string
        well: this.selectedLocations.wells ? JSON.stringify(this.selectedLocations.wells) : [],  // Convert to JSON string
        reservoir: this.selectedLocations.reservoirs ? JSON.stringify(this.selectedLocations.reservoirs) : [],  // Convert to JSON string
      };
      // Reset dependent filters based on the columnName
      if (columnName === 'assets') {
        query.asset = '[]';  // Empty array as a string for asset
        query.field = '[]';  // Empty array for fields
        query.well = '[]';   // Empty array for wells
        query.reservoir = '[]';  // Empty array for reservoirs
      } else if (columnName === 'fields') {
        query.field = '[]';  // Empty array for fields
        query.well = '[]';   // Empty array for wells
        query.reservoir = '[]';  // Empty array for reservoirs
      } else if (columnName === 'reservoirs') {
        query.reservoir = '[]';  // Empty array for reservoirs
      } else if (columnName === 'wells') {
        query.well = '[]';  // Empty array for wells
      }

      try {
        // Make the API call with the query parameters
        let response = "";
        let locations = "";
        if (columnName == "sampleKind") {
          response = await api.get(`/public/api/v1/summary/${columnName}`, { params: query });
          locations = response.data.data.columnSummary || [];
          this.searchDropdownItems[columnName] = locations.sort((a, b) => a.name.localeCompare(b.name));
        }
        else if (columnName == "tests") {
          response = await api.get(`/public/api/v1/list/${columnName}`);
          locations = response.data.data.list || [];
          this.searchDropdownItems[columnName] = locations;
        } else {
          response = await api.get(`/public/api/v1/locations/${columnName}`, { params: query });
          locations = response.data.data.locations || [];
          this.searchDropdownItems[columnName] = locations.sort((a, b) => a.name.localeCompare(b.name));
        }

      } catch (error) {
        console.error(`Error fetching locations for ${columnName}:`, error);
        this.searchDropdownItems[columnName] = [];
      } finally {
        this.searchLoading = false;
      }
    },

    selectLocation(column, location) {
      this.selectedLocations[column] = location; // Track the selected value
      this.searchDropdowns[column] = false; // Close the dropdown
      this.$emit('getFilterFromTable', {
        ...this.selectedLocations,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      });
      // Emit the updated filters
      this.loadItems(); // Reload the table data
    },
    resetTableFilter() {
      this.searchDropdownItems = [];
      this.selectedLocations = [];
    },
    async downloadTableCSV() {
      this.loadingCsv = true;
      if (this.samplesCSV.length == 0) {
        // Fetch the data if not already loaded
        await this.fetchSamples();
      }

      // Define the headers
      const headers = ['Asset', 'Field', 'Reservoir', 'Well', 'Sample Kind', 'Tests'];

      // Prepare the rows
      const rows = this.samplesCSV.map((item) => {
        const tests = [];

        if (item.composition) tests.push(item.composition.name || 'Composition');
        if (item.ccetests?.length) tests.push(item.ccetests[0].name || 'CCE Test');
        if (item.dltests?.length) tests.push(item.dltests[0].name || 'DL Test');
        if (item.cvdtests?.length) tests.push(item.cvdtests[0].name || 'CVD Test');
        if (item.separatortests?.length) tests.push(item.separatortests[0].name || 'Separator Test');
        if (item.viscositytests?.length) tests.push(item.viscositytests[0].name || 'Viscosity Test');

        return [
          item.location.asset || '',
          item.location.field || '',
          item.location.reservoir || '',
          item.location.well || '',
          item.location.sampleKind || '',
          tests.join(', '), // Combine tests into a single string
        ];
      });

      // Combine headers and rows into CSV content
      const csvContent = [
        headers.join(','), // Convert headers to CSV format
        ...rows.map((row) => row.map((cell) => `"${cell}"`).join(',')), // Escape cells and join rows
      ].join('\n');

      // Trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Fluids_Characterization_Data.csv';
      link.click();
      URL.revokeObjectURL(link.href);
      this.loadingCsv = false;
    },
    async fetchSamples() {
      try {
        const params = {
          pageSize: this.totalItems,
          pageNumber: 1,
          viewSummary: true
        };
        if (this.filterDetails) {
                    params.field = this.filterDetails.field?.length ? `["${this.filterDetails.field.join('","')}"]` : '[]';
                    params.well = this.filterDetails.well?.length ? `["${this.filterDetails.well.join('","')}"]` : '[]';
                    params.asset = this.filterDetails.asset?.length ? `["${this.filterDetails.asset.join('","')}"]` : '[]';
                    params.reservoir = this.filterDetails.reservoir?.length ? `["${this.filterDetails.reservoir.join('","')}"]` : '[]';
                    params.sampleKind = this.filterDetails.sampleKind?.length ? `["${this.filterDetails.sampleKind.join('","')}"]` : '[]';
                    params.selectedTests = this.filterDetails.selectedTests?.length 
  ? `["${this.filterDetails.selectedTests.map(test => test.toLowerCase().replace(/\s+/g, '')).join('","')}"]` 
  : '[]';
                }
        const response = await api.get('public/api/v1/samples', { params });
        this.samplesCSV = response.data.data.samples; // Assuming the API response has this structure
      } catch (error) {
        console.error('Error fetching samples:', error);
      }
    },

  },
  watch: {
    filterData: {
      handler() {
        this.loadItems();
      },
      immediate: true,
      deep: true
    },
    isLoading: {
      handler(newVal) {
        this.loading = newVal;
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.exploration-table {
  /* height: calc(100vh - 425px); */
  /* overflow-y: auto; */
  position: relative;
}

.table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.applied-filters {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Lato", sans-serif !important;
}

.applied-filters>span {
  margin-right: 10px;
  border-right: 1px solid #FFFFFF;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.applied-filters span:nth-child(5) {
  border-right: none !important;
}

.test-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* .v-overlay__content {
  position: absolute;
  z-index: 1000;
  min-width: 200px;
} */

.filter-dropdown {
  background: white;
  width: 100%;
  border-radius: 4px;
  max-height: 200px;
}
</style>