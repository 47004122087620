<template>
  <div class="composition-page">
    <div class="chart-table-wrap px-0 pb-0">
      <div class="chart-wrap">
      <v-row>
        <v-col cols="12" md="6">
          <ProducedGasPressureChart :data="getChartData" :loading="loading" />
        </v-col>
        <v-col cols="12" md="6">
          <ProducedGasComponentChart :data="getChartData" :loading="loading" />
          </v-col>
        </v-row>
      </div>
      <div>
        <div class="table-wrap border-0" v-if="cvdComposition && cvdComposition.length > 0 && !loading">
          <div class="table-title-div">
            <p class="table-title">Liberated Gas Compositions</p>
            <div class="csv-btn" @click="downloadTableCSV">
              <img class="pointer" :src="documentDownload" alt="" />
              <div>
                Download CSV
              </div>
            </div>
          </div>
          <table class="data-table">
            <tr class="table-sub-header">
              <td class="d-flex align-center img-td">
                <span class="mr-2">Pressure (psia) </span>
                <v-img :src="arrow_right" alt="Arrow" width="20" height="20" />
              </td>
              <td class="text-left" v-for="component in uniqueStepPressure" :key="component">
                {{ component }}
              </td>
            </tr>
            <tr class="component-td">
              <td class="d-flex align-center img-td">
                <span class="mr-2">Component</span>
                <v-img :src="arrow_down" alt="Arrow" width="20" height="20" />
              </td>
              <td class="text-left" v-for="component in uniqueStepPressure" :key="component">
                Mole%
              </td>
            </tr>
            <tbody>
              <tr v-for="component in uniqueFluidComponents" :key="component">
                <td class="text-left">{{ component }}</td>
                <td class="text-left" v-for="(entry) in cvdComposition.filter(
                  (entry) => entry.fluidComponentReference === component
                )" :key="entry.stepPressure">
                  {{
                    formatFraction(entry.vapor_MoleFraction)
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-left">Total</td>
                <td class="text-left" v-for="pressure in uniqueStepPressure" :key="pressure">
                  {{ calculateSum(pressure) }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="liberated-bottom-table">
            <div class="table-title-div">
              <p class="table-title">Calculated properties of Total Sample @ Standard Conditions</p>
            </div>
            <table class="data-table data-table-liberated">
              <tr class="main-header">
                <td class="main-header-td">
                  <span class="mr-2">MW (g/mol)</span>
                </td>
                <td v-for="component in MW" :key="component">
                  {{ component }}
                </td>
              </tr>
              <tr class="main-header">
                <td class="main-header-td">
                  <span class="mr-2">Gravity (Air=1)</span>
                </td>
                <td v-for="component in Gravity" :key="component">
                  {{ component }}
                </td>
              </tr>
            </table>
          </div>

          <div class="liberated-bottom-table">
            <div class="table-title-div">
              <p class="table-title">Calculated properties of C7 @ Standard Conditions</p>
            </div>
            <table class="data-table data-table-liberated">
              <tr class="main-header">
                <td class="main-header-td">
                  <span class="mr-2">MW (g/mol)</span>
                </td>
                <td v-for="component in MW" :key="component">
                  {{ component }}
                </td>
              </tr>
              <tr class="main-header">
                <td class="main-header-td">
                  <span class="mr-2">Density (g/cc)</span>
                </td>
                <td v-for="component in Density" :key="component">
                  {{ component }}
                </td>
              </tr>
            </table>
          </div> -->
        </div>
        <div v-else-if="!loading && !cvdComposition" class="text-center pt-15">
      <h4>Table data is not available.</h4>
    </div>
    <div v-else-if="loading">
      <screen-loader></screen-loader>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProducedGasPressureChart from "./ProducedGasPressureChart.vue";
import ProducedGasComponentChart from "./ProducedGasComponentChart.vue";
import axios from "axios";
import ScreenLoader from "../Loaders/ScreenLoader.vue";

export default {
  name: "CvdTestProperties",
  components: {
    ProducedGasComponentChart,
    ProducedGasPressureChart,
    ScreenLoader,
  },
  data: () => ({
    arrow_right: require("../../../assets/Images/Assets/arrow-right.png"),
    arrow_down: require("../../../assets/Images/Assets/arrow-down.png"),
    cvdComposition: [],
    tenantId: localStorage.getItem("tenantId"),
    loading: true,
    documentDownload: require("../../../assets/Images/csv.svg"),
  }),
  computed: {
    //  WM() {
    //   if (!this.cvdComposition) {
    //     return;
    //   }
    //   return [...new Set(this.cvdComposition.map(entry => entry.MW))];
    // },
    // Gravity() {
    //   if (!this.cvdComposition) {
    //     return;
    //   }
    //   return [...new Set(this.cvdComposition.map(entry => entry.Gravity))];
    // },
    // Density() {
    //   if (!this.cvdComposition) {
    //     return;
    //   }
    //   return [...new Set(this.cvdComposition.map(entry => entry.Density))];
    // },
    uniqueFluidComponents() {
      if (!this.cvdComposition) {
        return;
      }
      return [
        ...new Set(
          this.cvdComposition.map((entry) => entry.fluidComponentReference)
        ),
      ];
    },
    uniqueStepPressure() {
      if (!this.cvdComposition) {
        return;
      }
      return [
        ...new Set(this.cvdComposition.map((entry) => entry.stepPressure)),
      ];
    },
    getChartData() {
      if (!this.cvdComposition) {
        return;
      }
      return this.cvdComposition.map((entry) => ({
        fluidComponentReference: entry.fluidComponentReference,
        stepPressure: entry.stepPressure,
        vaporMoleFraction: entry.vapor_MoleFraction,
      }));
    },
  },
  methods: {
    calculateSum(stepPressure) {
      if (!this.cvdComposition) {
        return 0;
      }
      const filteredEntries = this.cvdComposition.filter(
        (entry) => entry.stepPressure === stepPressure
      );
      return filteredEntries.reduce((sum, entry) => {
        return (
          sum +
          (this.toggleFraction
            ? parseFloat(entry.vapor_MoleFraction || 0)
            : parseFloat(entry.vapor_MassFraction || 0))
        );
      }, 0).toFixed(3);
    },
    onSelectSort() {
      this.getTableData();
    },
    formatFraction(value) {
    if (value === null || value === undefined) {
      return "-";
    }
    return value.toFixed(3);
  },
    getTableData() {
      var sampleId = localStorage.getItem("sampleId");
      var cvdId = localStorage.getItem("testId");
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/cvdtests/${cvdId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.cvdComposition = response.data.data.cvdtest.cvdComposition;
            if (!this.cvdComposition) {
              return;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadTableCSV() {
  if (this.cvdComposition && this.cvdComposition.length > 0) {
    const fileName = 'Produced_gas_compositions.csv';

    // Construct CSV headers
    const headerRow1 = ['Pressure (psia)', ...this.uniqueStepPressure];
    const headerRow2 = ['Component', ...this.uniqueStepPressure.map(() => 'Mole%')];

    // Construct CSV rows
    const csvRows = this.uniqueFluidComponents.map(component => {
      const row = [component];
      this.uniqueStepPressure.forEach(pressure => {
        const entry = this.cvdComposition.find(e => e.fluidComponentReference === component && e.stepPressure === pressure);
        const value = entry?.vapor_MoleFraction;
        row.push(this.formatFraction(value));
      });
      return row;
    });

    // Combine headers and rows into CSV content
    const csvContent = [
      headerRow1.join(','),
      headerRow2.join(','),
      ...csvRows.map(row => row.join(','))
    ].join('\n');

    // Create and trigger CSV download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName); // Set filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Your browser does not support downloading files programmatically. Please try a different browser.');
    }
  } else {
    console.error('No data available in the table to download.');
  }
},
  },
  mounted() {
    this.getTableData();
  },
};
</script>