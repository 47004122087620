<template>
  <div class="data-management">
    <v-row no-gutters>
      <GridLoaders v-if="showLoader" />
      <v-col v-else-if="fields.length > 0" v-for="(field, index) in fields" :key="index" cols="12" md="6" lg="4" xl="3"
        @click="navigateToWells(field)">
        <v-sheet class="mb-2 pr-2">
          <v-card elevation="3" class="v-card-asset">
            <v-card-title class="assets_name">Field : {{ field.name }}
            </v-card-title>
            <v-card-text class="assets-data">
              <!-- <div>Asset : <span>{{ assetName }}</span></div> -->
              <div class="top-info">
                <div class="go-link" @click="navigateToWells(field)">Well <span>{{ field.counts.wells }}</span></div>
                <div>Tests <span>{{ field.counts.totalTests }}</span></div>
                <div>Sample <span>{{ field.counts.samples }}</span></div>
              </div>
              <div class="test-count">
                <div>Compositions <span>{{ field.counts.compositions }}</span></div>
                <div>Separator Tests <span>{{ field.counts.separatortests }}</span></div>
                <div>CCE Tests <span>{{ field.counts.ccetests }}</span></div>
                <div>Viscosity Tests <span>{{ field.counts.viscositytests }}</span></div>
                <div>DL Tests <span>{{ field.counts.dltests }}</span></div>
                <div>CVD Tests  <span>{{ field.counts.cvdtests }}</span></div>
              </div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
        </div>
      </div>
      <div class="no-asset-div" v-else>
        <h4>No field(s) found.</h4>
      </div>
    </v-row>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import GridLoaders from "../Loaders/GridLoaders.vue";

export default {
  name: "SampleFields",
  components: {
    GridLoaders,
  },
  data: () => ({
    fields: [],
    showLoader: false,
    assetName: "",
    timeoutMessage: "",
  }),
  methods: {
    fetchData() {
      this.showLoader = true;
      this.timeoutMessage = "";
      this.assetName = this.$route.params.asset.replace(/-/g, " ");
      const sanitizedAssetName = this.assetName.replace(/\\/g, "/");
      const assetString = JSON.stringify(sanitizedAssetName);

      api
        .get(`public/api/v1/locations/fields?asset=${encodeURIComponent(assetString)}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.fields = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToWells(field) {
      const fieldName = field.name.replace(/\s/g, "-");
      const sanitizedFieldName = fieldName.replace(/\//g, "\\");
      this.$router.push({
        name: "Wells",
        params: { field: sanitizedFieldName },
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>