<template>
  <div>
    <nav>
      <v-app-bar color="white" :elevation="0">
        <Workspace class="d-none" />
        <div class="navbar-content">

          <div class="navbar-text">{{ menuTitle }}</div>

          <div class="navbar-right-sec">
            <!-- <v-menu rounded offset-y>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon class="mr-1">
                  <v-avatar size="20">
                    <v-img
                      src="../../../assets/Images/Navbar/settings.png"
                    ></v-img>
                  </v-avatar>
                </v-btn>
              </template>
<v-card v-if="getTenantData && getTenantData.length > 1">
  <v-card-text>
    <v-btn rounded variant="text" @click="openModal">
      Change tenant
    </v-btn>
  </v-card-text>
</v-card>
</v-menu> -->

            <!-- <v-btn icon class="mr-1">
              <v-badge content="2">
                <v-avatar size="20">
                  <v-img
                    src="../../../assets/Images/Navbar/notification.png"
                  ></v-img>
                </v-avatar>
              </v-badge>
            </v-btn> -->

            <!-- <v-text-field
              :loading="loading"
              density="compact"
              variant="solo"
              label="Sample Search Query"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
              @click:append-inner="onClick"
            ></v-text-field>

            <v-btn class="setting-btn">
                <v-avatar size="24">
                  <v-img
                    src="../../../assets/Images/Navbar/settings.png"
                  ></v-img>
                </v-avatar>
            </v-btn>

            <v-btn class="setting-btn">
                <v-avatar size="24">
                  <v-img
                    src="../../../assets/Images/Navbar/notification.png"
                  ></v-img>
                </v-avatar>
            </v-btn> -->

            <!-- <v-divider class="vertical-divider"
              thickness="2"
              vertical
            ></v-divider> -->

            <v-menu rounded>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="variant" class="user-dropdown">
                  <v-avatar size="32">
                    <v-img :src="user.profileImage" alt="profile"></v-img>
                  </v-avatar>
                  <h5 class="user-name">{{ userFullName }}</h5>
                  <v-avatar size="20">
                    <v-img src="../../../assets/Images/Navbar/chevron down.png" alt="arrow down"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card class="user-dropdown-menu">
                <v-card-text>
                  <div class="mx-auto text-center">
                    <v-avatar size="24">
                      <v-img :src="user.profileImage" alt="profile"></v-img>
                    </v-avatar>
                    <h5 class="user-name">{{ userFullName }}</h5>
                    <p class="text-email">
                      {{ userEmail }}
                    </p>
                    <v-divider class="my-2"></v-divider>
                    <!-- <v-btn rounded variant="text"> Edit Account </v-btn> -->
                    <v-btn
                      rounded
                      variant="text"
                      class="user-menu"
                      @click="openModal"
                    >
                      Change tenant
                    </v-btn>
                    <v-divider class="my-2"></v-divider>
                    <v-btn
                      rounded
                      variant="text"
                      class="user-menu"
                      @click="logOut"
                    >
                      Logout
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>

            <!-- <v-avatar size="36" rounded="0" class="ml-1 filter-btn">
              <v-img src="../../../assets/Images/Navbar/grid.png"></v-img>
            </v-avatar> -->
          </div>
        </div>
        <Links />
      </v-app-bar>
    </nav>
    <v-dialog v-model="showModal" max-width="600" class="tenant-dialog">
      <v-card>
        <v-card-title class="px-0 d-flex align-center justify-between">Tenant Data
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" flat icon class="chart-preview-close-btn" size="x-small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 200px">
          <v-select v-if="getTenantData && getTenantData.length > 1" @update:modelValue="onSelectTenant"
            v-model="selectedTenant" :items="getTenantData.map((tenant) => tenant.name)"
            placeholder="Please select tenant">
          </v-select>
          <v-list v-else>
            <v-list-item> No tenant data available. </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :model-value="overlay" class="align-center logout-loader justify-center">
      <v-progress-circular color="primary" size="48" indeterminate></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="getLogoutError">
      <div class="text-center" v-html="getLogoutError"></div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Workspace from "./Workspace.vue";
import Links from "./Links.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    user: {
      fullName: "John Doe",
      email: "johndoe@gmail.com",
      profileImage: require("../../../assets/Images/Navbar/profile 1.png"),
    },
    showModal: false,
    selectedTenant: localStorage.getItem("tenantName"),
    drawer: false,
    group: null,
    tenantId: localStorage.getItem("tenantId"),
    overlay: false,
    snackbarMessage: null,
    timeoutMessage: "",
    localStorageMenuTitle: localStorage.getItem("activeMenu"),
  }),
  components: {
    Workspace,
    Links,
  },
  computed: {
    ...mapGetters("auth", ["getExpirationTime"]),
    ...mapGetters("tenantId", ["getTenantData", "getSelectedTenant", "getUser"]),
    userFullName() {
      if (this.getUser && this.getUser.firstName && this.getUser.lastName) {
        return `${this.getUser.firstName} ${this.getUser.lastName}`;
      }
    },
    menuTitle() {
      return this.localStorageMenuTitle;
    },
    userEmail() {
      return this.getUser.username;
    },
    getLogoutError() {
      const error = this.$store.getters["auth/getLogoutError"];
      if (error) {
        this.snackbarMessage = error;
      }
      return error;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    localStorageMenuTitle(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localStorageMenuTitle = localStorage.getItem("activeMenu");
      }
    },
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    openModal() {
      this.showModal = true;
    },
    logOut() {
      this.overlay = true;
      this.timeoutMessage = ''
      this.sendLogoutRequest()
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            this.overlay = false;
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.overlay = false;
        })
    },
    callTenants() {
      if (localStorage.getItem("authToken")) {
        this.$store.dispatch("tenantId/fetchTenants");
      }
    },
    closeModal() {
      this.showModal = false;
    },
    onSelectTenant(selectedValue) {
      const selectedTenant = this.getTenantData.find(
        (tenant) => tenant.name === selectedValue
      );

      if (selectedTenant) {
        const { name, ID } = selectedTenant;
        localStorage.removeItem('lastVisitedDashboard');
        localStorage.removeItem('savedChartData');
        localStorage.removeItem('lastFilterId');
        localStorage.setItem("tenantId", ID);
        localStorage.setItem("tenantName", name);
        this.$store.commit("tenantId/SET_SELECTED_TENANT", { name, ID });
        this.$router.push("/assets");
        this.closeModal();
      }
    },
    updateMenuTitle() {
      this.localStorageMenuTitle = localStorage.getItem("activeMenu");
    }
  },
  mounted() {
    this.callTenants();
    this.menuTitleInterval = setInterval(() => {
      this.updateMenuTitle();
    }, 100);
  },
};
</script>