<template>
    <div>
      <div
        v-for="(variable, index) in variablesToPlot"
        :key="index"
        class="chart-column"
      >
        <div :ref="`chart-${index}`" class="main-chart mr-md-2">
          <v-btn
            @click="openModal(index)"
            flat
            size="small"
            class="chart-preview-btn2 text-none"
            >Expand</v-btn
          >
        </div>
  
        <v-dialog
          v-model="isPreviewOpen[index]"
          width="100vw"
          class="multi-chart-preview-div"
        >
          <v-card>
            <div class="modal-header header-div">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeModal(index)"
                flat
                icon
                class="chart-preview-close-btn"
                size="x-small"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <div
                :ref="`modal-chart-${index}`"
                class="modal-chart-container"
              ></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </template>
    
  <script>
  import Plotly from "plotly.js-dist-min";
  let oil_data = require("../../../assets/JS/DifferentialLiberation");
  
  export default {
    name: "DataInventoryAnalysisPvt",
    data() {
      const oilData = oil_data[0].DLTest.DLTestStep;
      let stepPressureValues = oilData.map((step) =>
        parseFloat(step.StepPressure)
      );
      const variablesToPlot = [
        "Gas Gravity",
        "Gas Gravity Cumulative",
        "Gas Density",
        "Gas Z Factor",
      ];
  
      const isPreviewOpen = Array(variablesToPlot.length).fill(false);
  
      return {
        variablesToPlot: variablesToPlot,
        stepPressureValues: stepPressureValues,
        chartRefs: [],
        isPreviewOpen: isPreviewOpen,
      };
    },
    methods: {
      async plotCharts() {
        const oilData = oil_data[0].DLTest.DLTestStep;
  
        for (let index = 0; index < this.variablesToPlot.length; index++) {
          const variable = this.variablesToPlot[index];
          const originalVariable = variable.replace(/\s/g, "");
          const dataValues = oilData.map((step) =>
            parseFloat(step[originalVariable])
          );
  
          // Filter out NaN values from the data array
          const filteredData = dataValues.filter((value) => !isNaN(value));
  
          const trace = {
            x: this.stepPressureValues,
            y: filteredData,
            type: "bar",
            name: variable,
          };
  
          const layout = {
            
            xaxis: { title: "Pressure (psia)" },
            // yaxis: { title: `${variable}` },
            yaxis: {title: "Frequency"},
          };
          const config = { 
            responsive: true,
            displaylogo: false,
         modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d']
          };
  
          // Create a new div element for the chart
          const newDiv = document.createElement("div");
          this.$refs[`chart-${index}`][0].appendChild(newDiv);
          this.chartRefs.push(newDiv); // Store the reference
  
          await Plotly.newPlot(this.chartRefs[index], [trace], layout, config);
        }
      },
      openModal(index) {
        this.$data.isPreviewOpen[index] = true;
  
        this.$nextTick(() => {
          this.plotModalChart(index);
        });
      },
      closeModal(index) {
        this.$data.isPreviewOpen[index] = false;
      },
      async plotModalChart(index) {
        const variable = this.variablesToPlot[index];
        const originalVariable = variable.replace(/\s/g, "");
        const oilData = oil_data[0].DLTest.DLTestStep;
        const dataValues = oilData.map((step) =>
          parseFloat(step[originalVariable])
        );
  
        // Filter out NaN values from the data array
        const filteredData = dataValues.filter((value) => !isNaN(value));
  
        const trace = {
          x: this.stepPressureValues,
          y: filteredData,
          type: "bar",
          name: variable,
        };
  
        const layout = {
          
          xaxis: { title: "Pressure (psia)" },
          // yaxis: { title: `${variable}` },
          yaxis: {title: "Frequency"},
        };
        const config = { 
          responsive: true,
          displaylogo: false,
         modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d']
        };
  
        // Use this.$nextTick to ensure the modal has been rendered before Plotly.newPlot
        this.$nextTick(() => {
          // Access the modal-chart ref
          const modalChartRef = this.$refs[`modal-chart-${index}`];
  
          if (modalChartRef && modalChartRef[0]) {
            Plotly.newPlot(modalChartRef[0], [trace], layout, config);
          } else {
            console.error("Modal chart reference not found");
          }
        });
      },
    },
    mounted() {
      this.plotCharts();
    },
  };
  </script>
    