<template>
  <div class="data-management">
    <v-row no-gutters class="pr-0 mb-2">
      <!-- <div class="toggle-btn-div">
        <div class="label-toggle">
          <span>Expand all samples</span>
        </div>
        <div>
          <v-switch v-model="expandAllSamples" color="info"></v-switch>
        </div>
        <div class="label-toggle">
          <span>Show all samples</span>
        </div>
        <div>
          <v-switch v-model="showAllSamples" color="info"></v-switch>
        </div>
      </div> -->
      <v-col cols="12" class="pt-4">
        <div class="well-top-wrap">
          <h6>Samples</h6>
          <div class="expand-all-checkbox" v-if="samples.length > 1">
            <input type="checkbox" v-model="expandAllSamples" @change="toggleExpandAll" />
            <span>Expand All Samples</span>
          </div>
        </div>
        <TabularLoaders v-if="showLoader" />
        <v-table v-else-if="samples.length > 0" class="well-table sample-table">
          <thead>
            <tr class="main-header">
              <th>Sample ID</th>
              <th>Sample Kind</th>
              <th>Expand/Collapse</th>
            </tr>
          </thead>
          <tbody class="well-table-content">
            <template v-for="(sample, index) in samples" :key="index">
              <tr :style="{ background: sample.expanded ? '#00000008' : '#FFFFFF' }" @click="sample.expanded = !sample.expanded" class="cursor-pointer">
                <td :style="{ borderBottom: sample.expanded ? 'none' : '1px solid #E0E0E0' }"><span class="clickable-cell no-bg">{{ sample.fluidSampleID }}</span></td>
                <td :style="{ borderBottom: sample.expanded ? 'none' : '1px solid #E0E0E0' }">{{ sample.sampleKind }}</td>
                <td :style="{ borderBottom: sample.expanded ? 'none' : '1px solid #E0E0E0' }">
                  <div class="expand-collapse-icon">
                    <v-img :src="sample.expanded ? up : down" class="cursor-pointer"></v-img>
                  </div>
                </td>
              </tr>
              <tr v-if="sample.expanded" class="expanded-tr">
                <td colspan="5" class="sample-expanded-div pt-2">
                  <div v-if="sampleHasTests(sample)" class="d-flex align-center justify-space-between">
                    <div class="sample-test-wrapper">
                    <div v-for="(item, itemIndex) in comparisonArray" :key="item" class="sub-test-wrap" :style="{ marginRight: sample[item] !== undefined ? '8px' : '0px', marginTop: sample[item] !== undefined ? '4px' : '0px', marginBottom: sample[item] !== undefined ? '4px' : '0px' }">
                      <template v-if="sample[item] !== undefined">
                        <template v-if="Array.isArray(sample[item])">
                          <div v-for="(test, testIndex) in sample[item]" :key="`${testIndex}-${itemIndex}`"
                            class="well-content sample-content " 
                            @click="openTestPage(sample, item, testIndex, itemIndex)"
                           >
                            <div class="sample-test-div">
                              <img :src="test_image" alt="Panel Image" />
                            </div>
                            {{ displayTestType(item) }}
                            <span v-if="sample[item].length > 1" class="test-temperature">
                              (Test # {{ testIndex + 1 }})
                            </span>
                            <span class="test-temperature" v-if="test.testTemperature && test.testTemperature_UOM">
                              ({{ test.testTemperature }} {{ test.testTemperature_UOM }})
                            </span>
                          </div>
                        </template>
                        <div v-else class="well-content sample-content" 
                          @click="openTestPage(sample, item, itemIndex)">
                          <div class="sample-test-div">
                            <img :src="test_image" alt="Panel Image" class="mr-2" width="20" height="20" />
                          </div>
                          {{ displayTestType(item) }}
                          <span class="test-temperature"
                            v-if="sample[item].testTemperature && sample[item].testTemperature_UOM">
                            ({{ sample[item].testTemperature }} {{ sample[item].testTemperature_UOM }})
                          </span>
                        </div>
                      </template>
                    </div>
                    </div>
                    <div>
                      <div v-if="pdfLoaders[sample.ID]" class="pdf-div">
                        <img :src="pdf_loader_image" alt="loader Image" />
                      </div>
                      <div v-else @click="goToPdf(sample)" class="test-pdf-div">
                        <v-btn class="pdf-btn">
                          <img :src="pdf_icon" alt="pdf icon" />
                          <span class="pdf-text">Download PDF</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div v-else class="loader-div justify-start pl-3">
                    No test found.
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
        <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
          <div class="text-center">
            {{ timeoutMessage }}
            <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
          </div>
        </div>
        <div class="no-asset-div" v-else-if="samples.length == 0 && showLoader == false && timeoutMessage == ''
        ">
          <h4>No sample(s) founds.</h4>
        </div>
      </v-col>
    </v-row>
    <ErrorPopup />
  </div>
</template>

<script>
import api from "../../../axiosInterceptor"
import TabularLoaders from "../Loaders/TabularLoaders.vue";
import { mapActions } from "vuex";
import ErrorPopup from '../Popups/ErrorPopup.vue';

export default {
  name: "Sample",
  components: {
    TabularLoaders,
    ErrorPopup,
  },
  data() {
    return {
      samples: [],
      sampleIdString:'',
      up: require("../../../assets/Images/Buttons/up.png"),
      down: require("../../../assets/Images/Buttons/down.png"),
      tenantId: localStorage.getItem("tenantId"),
      sample_image: require("../../../assets/Images/Assets/wells.png"),
      test_image: require("../../../assets/Images/Assets/sample.png"),
      pdf_icon: require("../../../assets/Images/pdfIcon.svg"),
      pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
      allTests: [],
      loader: true,
      openPanelIndex: null,
      showLoader: true,
      showAllSamples: false,
      pdfLoaders: {},
      snackbarMessage: null,
      expandedPanels: [],
      selectedSampleID: null,
      comparisonArray: [
        "composition",
        "dltests",
        "ccetests",
        "cvdtests",
        "separatortests",
        "viscositytests",
      ],
      expandAllSamples: false,
      timeoutMessage: "",
    };
  },
  computed: {
    displayTestType() {
      return function (testType) {
        switch (testType) {
          case "composition":
            return "Fluid Composition";
          case "dltests":
            return "DL Test";
          case "ccetests":
            return "CCE Test";
          case "cvdtests":
            return "CVD Test";
          case "separatortests":
            return "Separator Test";
          case "viscositytests":
            return "Viscosity Test";
          default:
            return testType;
        }
      };
    },
    filteredComparisonArray() {
      // Filter out items without valid data
      return this.comparisonArray.filter(item => 
        this.sample[item] && (
          Array.isArray(this.sample[item]) ? this.sample[item].length > 0 : true
        )
      );
    },
  },
  methods: {
    ...mapActions("test", ["setActiveTestIndex"]),
    ...mapActions("api", ["showSnackbar"]),
    fetchData(wellName,sampleId) {
      this.showLoader = true;
      this.timeoutMessage = "";
      const wellString = JSON.stringify(wellName);
      this.sampleIdString = JSON.stringify(sampleId);

      api
        .get(`public/api/v1/samples`, {
          params: {
            well: wellString,
            viewSummary: true,
            id:this.sampleIdString
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            if (this.showAllSamples) {
              this.samples = response.data.data.samples;
            } else {
              this.samples = response.data.data.samples.filter(
                (sample) => sample.IsTestAvailable
              );
            }
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    sampleHasTests(sample) {
      return sample.IsTestAvailable;
    },
    toggleExpandAll() {
      this.samples.forEach((sample) => {
        sample.expanded = this.expandAllSamples;
      });
    },
    expandAll() {
      this.expandedPanels = this.samples.map((sample) => sample.ID);
    },
    collapseAll() {
      this.expandedPanels = [];
    },
    showTests(sample) {
      this.loader = true;
      const sampleId = sample.ID;
      api
        .get(
          `public/api/v1/samples/${sampleId}/tests`
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.loader = false;
            this.allTests = response.data.data.allTests;
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    openTestPage(sample, item, index) {
      // Filter comparisonArray to include only items present in the sample object
      const filteredComparisonArray = this.comparisonArray.filter(
        (comparisonItem) => sample.hasOwnProperty(comparisonItem)
      );

      // Find the index of the clicked item within the filtered comparisonArray
      let cumulativeIndex = 0;
      let itemIndex = -1;
      for (let i = 0; i < filteredComparisonArray.length; i++) {
        if (filteredComparisonArray[i] === item) {
          itemIndex = cumulativeIndex;
          break;
        }
        if (Array.isArray(sample[filteredComparisonArray[i]])) {
          cumulativeIndex += sample[filteredComparisonArray[i]].length;
        } else {
          cumulativeIndex++;
        }
      }

      this.setActiveTestIndex(itemIndex);
      const sampleId = sample.ID;
      const testType = item.endsWith("s") ? item.slice(0, -1) : item;
      let testId;
      if (testType === "composition") {
        testId = sample[item].ID;
      } else {
        testId = sample[item][index].ID;
      }
      localStorage.setItem("sampleId", sampleId);
      localStorage.setItem("testId", testId);
      // const routeParams = { ...this.$route.params };
      // let newRoute = `/assets/${routeParams.asset}/${routeParams.field}/${routeParams.well}/${testType}/${testId}`;
      let routeParams = localStorage.getItem("lastView")
      let newRoute = `${routeParams}/${testType}/${testId}`
      localStorage.setItem("lastView", newRoute);
      this.$router.push(newRoute);
      
    },
    goToPdf(sample) {
      this.snackbarMessage = null;
      this.pdfLoaders[sample.ID] = true;
      const sampleId = sample.ID;
      api
        .get(
          `public/api/v1/samples/${sampleId}/download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            this.pdfLoaders[sample.ID] = false;
            window.open(url, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.response) {
            this.pdfLoaders[sample.ID] = false;
          }
        });
    },
  },
  watch: {
    $route(to, from) {
      this.fetchData(to.params.well,to.params.sampleId);
    },
    showAllSamples(value) {
      this.fetchData(this.$route.params.well,this.$route.params.sampleId);
    },
    expandAllSamples() {
      if (this.expandAllSamples) {
        this.expandAll();
      } else {
        this.collapseAll();
      }
    },
  },
  mounted() {
    this.fetchData(this.$route.params.well,this.$route.params.sampleId);
  },
};
</script>
