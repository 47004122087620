<template>
  <div class="data-management well-page">
    <v-row no-gutters class="mb-2">
      <!-- <v-col cols="12" md="3">
      <WellsLeft @field-selected="fetchWells"/>
    </v-col> -->
      <v-col cols="12">
        <div class="well-top-wrap">
          <h6>Wells</h6>
          <div class="expand-all-checkbox" v-if="wells.length > 0">
            <input type="checkbox" v-model="expandAllWells" @change="toggleExpandAll" />
            <span>Expand All Wells</span>
          </div>
        </div>
        <TabularLoaders v-if="showLoader" />
        <v-table v-else-if="wells.length > 0" class="well-table sample-table well-table-data border-0">
          <thead>
            <tr class="main-header">
              <th>Well Number</th>
              <th>No. Samples</th>
              <th>Sample Kind</th>
              <th>Expand/Collapse</th>
            </tr>
          </thead>
          <tbody class="well-table-content">
            <template v-for="(well, index) in wells" :key="index">
              <tr :style="{ background: well.expanded ? '#00000008' : '#FFFFFF'}">
                <td @click="navigateToSamples(well)" :style="{ borderBottom: well.expanded ? 'none' : '1px solid #E0E0E0' }"> <span class="cursor-pointer clickable-cell well-cell no-bg">{{well.name}}</span></td>
                <td :style="{ borderBottom: well.expanded ? 'none' : '1px solid #E0E0E0' }">{{ well.counts.samples }}</td>
                <td :style="{ borderBottom: well.expanded ? 'none' : '1px solid #E0E0E0' }">{{ well.sampleKind }}</td>
                <td :style="{ borderBottom: well.expanded ? 'none' : '1px solid #E0E0E0' }">
                  <div class="expand-collapse-icon">
                    <v-img :src="well.expanded ? up : down" class="cursor-pointer" @click="well.expanded = !well.expanded"></v-img>
                  </div>
                </td>
              </tr>
              <tr v-if="well.expanded" class="expanded-tr">
                <td colspan="4" class="sample-expanded-div well-expanded-div">
                  <div class="well-expanded-div-content" v-for="sample in well.data" :key="sample.id">
                    <p @click="navigateToSamplesWithSampleId(well,sample.id)"  class="clickable-cell no-bg"> {{ sample.fluidSampleID }}</p>
                    <p> {{ sample.sampleKind }}</p>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
        <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
          <div class="text-center">
            {{ timeoutMessage }}
            <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
          </div>
        </div>
        <div class="no-asset-div" v-else-if="wells.length == 0 && showLoader == false && timeoutMessage == ''
          ">
          <h4>No well(s) founds.</h4>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor"
// import WellsLeft from "./WellsLeft.vue";
import TabularLoaders from "../Loaders/TabularLoaders.vue";
export default {
  name: "SampleWells",
  components: {
    // WellsLeft,
    TabularLoaders,
  },
  data() {
    return {
      wells: [],
      tenantId: localStorage.getItem("tenantId"),
      well_image: require("../../../assets/Images/Assets/wells.png"),
      up: require("../../../assets/Images/Buttons/up.png"),
      down: require("../../../assets/Images/Buttons/down.png"),
      showLoader: false,
      timeoutMessage: "",
      expandAllWells: false,
      expandedPanels: [],
    };
  },
   watch: {
    expandAllWells() {
      if (this.expandAllWells) {
        this.expandAll();
      } else {
        this.collapseAll();
      }
    },
   },
  methods: {
    toggleExpandAll() {
      this.wells.forEach((well) => {
        well.expanded = this.expandAllWells;
      });
    },
    expandAll() {
      this.expandedPanels = this.wells.map((well) => well.ID);
    },
    collapseAll() {
      this.expandedPanels = [];
    },
    fetchData() {
      this.showLoader = true;
      this.timeoutMessage = "";
      const fieldName = this.$route.params.field.replace(/-/g, " ");
      const sanitizedFieldName = fieldName.replace(/\\/g, "/");
      const fieldString = JSON.stringify(sanitizedFieldName);

      api
        .get(`public/api/v1/locations/wells?field=${encodeURIComponent(fieldString)}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.wells = response.data.data.locations.sort((a, b) => {
              return (
                parseInt(a.name.split("-")[1]) - parseInt(b.name.split("-")[1])
              );
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToSamples(well) {
      const wellName = well.name.replace(/\s/g, "-");
      this.$router.push({
        name: "Samples",
        params: { well: wellName},
      });
    },
    navigateToSamplesWithSampleId(well,sampleId) {
      const wellName = well.name.replace(/\s/g, "-");
      this.$router.push({
        name: "Samples",
        params: { well: wellName , sampleId: sampleId},
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.mdi-menu-up::before {
    content: "\F0360";
    transform: rotate(180deg);
}
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
</style>