<template>
  <div class="filter-wrapper">
    <v-form>
      <!-- <TabularLoaders v-if="showLoaders === true" class="pr-4" /> -->
      <v-row no-gutters>
        <!-- <v-col cols="12" class="pa-0 pb-3 save-update-chart-btns" v-if="createNewChart">
          <v-btn prepend-icon="mdi-plus" class="save-btn text-none" flat @click="goToCreateChart()">Chart</v-btn>
        </v-col> -->
        <v-col cols="12" class="studio-input-form" v-if="isFluidsDataStudio">
          <h6 class="select-chart-heading">Select Saved Chart</h6>
          <v-row no-gutters style="align-items: flex-end; width: 100%;">
            <v-col cols="12" lg="5" class="pa-0 pr-lg-3" v-if="shouldRenderChartList">
              <v-select v-model="selectedSavedChartName" :items="savedChartsNames" placeholder="Select Saved Chart"
                @update:modelValue="savedChartChange" hide-details>
                <!-- Show loading indicator while loading -->
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingSavedCharts">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingSavedCharts">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="7" class="pa-0 mt-lg-0" :class="{'mt-4': selectedSavedChartName != null && accessLevel == 'private', 'mt-0': !(selectedSavedChartName != null && accessLevel == 'private')}" >
              <div class="saved-chart-right-btns d-flex align-center justify-end">
                <v-btn v-if="selectedSavedChartName != null && accessLevel == 'private'" flat class="reset-btn text-none" @click="resetChartData">
                    <v-img src="../../../assets/Images/Navbar/add.png" alt="reset" width="20" height="20" class="mr-2"></v-img>
                  New Chart
                </v-btn>
                <v-btn v-if="selectedSavedChartName != null && accessLevel == 'private'" flat
                  class="delete-btn ml-2 text-none" @click="showDeleteConfirmation">
                    <v-img src="../../../assets/Images/delete-black.png" alt="delete" width="18" height="20" class="mr-2"></v-img>
                    Delete
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div class="chart-opts mt-4" :class="{ 'mb-0': !showInputs, 'mb-4': showInputs }">
            <h6 class="ma-0">Chart Options</h6>
            <img @click="toggleInputs" v-if="isFluidsDataStudio && !showInputs" :src="icon" alt="chevron-down">
            <img @click="toggleInputs" v-if="isFluidsDataStudio && showInputs" :src="icon2" alt="chevron-up">
          </div>
        </v-col>
        <div class="chart-opts-input" v-if="showInputs">
          <v-row no-gutters style="row-gap: 16px;">
            <v-col cols="12" md="6" lg="3" class="pa-0">
              <label for="">Enter Chart Title</label>
              <v-text-field v-model="chartTitle" required placeholder="Enter Chart Title" hide-details
                outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-md-4">
              <label for="">Select Table Type</label>
              <v-select v-model="selectedTableTypeName" :items="tableTypesNames" placeholder="Select Table Type"
                @update:modelValue="tableTypeChange" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingTableType">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingTableType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-lg-4">
              <label for="">Select Chart Type</label>
              <v-select v-model="selectedChartTypeName" :items="chartTypesNames" placeholder="Select Chart Type"
                @update:modelValue="chartTypeChange" :search="searchQuery" :disabled="selectedTableTypeName === null" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingChartType">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingChartType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-md-4" v-if="showXAxis">
              <label for="">Select Coulmn for X-Axis</label>
              <v-select v-model="selectedXAxisKey" :items="xAxisItems" item-title="displayName" item-value="name"
                placeholder="Select Column for X-Axis" @update:modelValue="handleXAxisSelection" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingXAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingXAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0" v-if="showYAxis">
              <label for="">Select Column(s) for Y-Axis</label>
              <v-select v-model="selectedYAxisKey" :items="yAxisItems" multiple chips clearable closable-chips
                placeholder="Select Column for Y-Axis" item-title="displayName" item-value="name" :return-object="false"
                  @update:modelValue="handleYAxisSelection" class="custom-y-axis-chips" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingYAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingYAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-md-4" v-if="showZAxis">
              <label for="">Select Column for Z-Axis</label>
              <v-select v-model="selectedZAxisKey" placeholder="Select Column for Z-Axis" :items="zAxisItems"
                item-title="displayName" item-value="name" @update:modelValue="handleZAxisSelection" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0" v-if="showColorGroup" :class="{'pl-4': showYAxis, 'pl-0': !showYAxis}">
              <label for="">Select Legend</label>
              <v-select v-model="selectedColorGroupKey" clearable placeholder="Select Legend"
                :items="colorGroupItems" item-title="displayName" item-value="name"
                @update:modelValue="handleColorGroupSelection" hide-details>
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="showXBinsInput" cols="12" md="6" lg="3" class="pa-0 pl-md-4">
              <label for="">Number of X bins</label>
              <div id="rangeValue">{{ xBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="xBins"
                @input="updateXBins" />
            </v-col>
            <v-col v-if="showYBinsInput" cols="12" md="6" lg="3" class="pa-0" :class="{'pl-4': !showZAxis && !showColorGroup, 'pl-0': showZAxis || showColorGroup}">
              <label for="">Number of Y bins</label>
              <div id="rangeValue">{{ yBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="yBins"
                @input="updateYBins" />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-lg-4 one" v-if="!showZAxis && !showColorGroup && !showXBinsInput && !showYBinsInput">
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-lg-4 two" v-if="showColorGroup && !showZAxis || !showXBinsInput || !showYBinsInput">
            </v-col>
             <v-col cols="12" md="6" lg="3" class="pa-0 pl-lg-4 four" v-if="showZAxis && showColorGroup && showXBinsInput && showYBinsInput">
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-0 pl-lg-4 four" v-if="showZAxis && showColorGroup && showXBinsInput && showYBinsInput">
            </v-col>
            <v-col class="d-flex align-end justify-end" cols="12" md="6" lg="3">
              <div class="studio-btns" v-if="showInputs">
                <div>
                  <v-btn v-if="selectedSavedChartName == null && createNewChart == false" flat :disabled="!isFormValid"
                    :loading="createLoading" class="save-btn text-none" @click="createChart">
                    <div class="save-btn-img">
                      <v-img src="../../../assets/Images/save btn.png" alt="save"></v-img>
                    </div>
                    <span>Save</span>
                  </v-btn>
                  <v-btn v-if="selectedSavedChartName != null && accessLevel == 'private'" flat :disabled="!isFormValid"
                    :loading="updateLoading" class="save-btn text-none" @click="updateChart">
                    <div class="save-btn-img">
                      <v-img src="../../../assets/Images/save btn.png" alt="update"></v-img>
                    </div>
                    <span>Update</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-col v-if="$route.name === 'DataInventoryAnalysis'" cols="12" class="pa-0">
          <label for="">Select Saved Dashboard</label>
          <v-select v-model="dashboard" placeholder="Select Saved Dashboard" :items="dashboardItems"
            @update:modelValue="handleDshboardSelection"></v-select>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
      </v-snackbar>
    </v-form>
    <v-dialog transition="dialog-top-transition" v-model="confirmDeleteDialog" width="417">
      <div class="cross-btn-div" @click="closeDeleteConfirmation">
        <v-img :src="cross" alt="close"></v-img>
      </div>
      <v-alert title="Delete Chart" text="Are you sure you want to delete chart?">
        <div class="alert-btns-div">
          <v-btn class="cancel-btn" flat @click="closeDeleteConfirmation">Cancel</v-btn>
          <v-btn class="delete-btn" flat @click="deleteChart" :loading="deleteLoading">
            <v-img :src="delete_icon" alt="delete-icon" width="16" height="15" class="mr-2"></v-img>
            Delete Chart</v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <StudioModal ref="childComponent" @open-dialog="openDialog" />
    <Filters  ref="filters" v-if="isFluidsDataStudio" @drawerStateChanged="handleRightDrawerStateChange"
      :callDefaultFilter="callDefaultFilter" :selectedType="selectedTableTypeName" :filterFromChart="filterFromChart"
      @filter-data="getTableDataAgainstFilter" @reset-saved-filter="handleResetSavedFilter"
      @reset-call-default-filter="resetCallDefaultFilter" />
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
// import TabularLoaders from "../Loaders/TabularLoaders.vue";
import Filters from "../Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import StudioModal from "./StudioModal.vue";
export default {
  name: "StudioDataLeft",
  components: { Filters, StudioModal },
  data() {
    return {
      cross: require("../../../assets/Images/Buttons/cross.png"),
      delete_icon: require("../../../assets/Images/delete btn.png"),
      confirmDeleteDialog: false,
      searchQuery: "",
      chartTypes: [],
      tableTypes: [],
      chartTitle: "",
      chartType: this.selectedChartType,
      xAxisItems: [],
      yAxisItems: [],
      zAxisItems: [],
      colorGroupItems: [],
      selectedXAxisKey: [],
      selectedYAxisKey: [],
      selectedZAxisKey: [],
      selectedColorGroupKey: null,
      xAxisData: [],
      yAxisData: [],
      zAxisData: [],
      colorGroupData: [],
      showXBinsInput: false,
      showXAxis: true,
      showYAxis: true,
      showZAxis: false,
      showColorGroup: false,
      xBins: 20,
      showYBinsInput: false,
      yBins: 20,
      chartTypesNames: [],
      tableTypesNames: [],
      selectedChartTypeName: null,
      selectedTableTypeName: null,
      selectedSavedChartName: null,
      savedCharts: [],
      savedChartsNames: [],
      tableData: [],
      showLoaders: false,
      deleteError: null,
      showTooltip: false,
      snackbar: {
        visible: false,
        message: "",
        color: "success",
        timeout: 3000,
      },
      createLoading: false,
      updateLoading: false,
      deleteLoading: false,
      loadingSavedCharts: false,
      loadingXAxisData: false,
      loadingYAxisData: false,
      loadingZAxisData: false,
      loadingTableType: false,
      loadingChartType: false,
      createNewChart: false,
      chartSaved: false,
      accessLevel: "",
      resetSavedFilter: false,
      callDefaultFilter: false,
      icon: require("../../../assets/Images//arrow-down.png"),
      icon2: require("../../../assets/Images/arrow-up.png"),
      showInputs: true,
      savedFilterData: [],
      savedType: "",
      colorGroupChanged: false,
      axisSelected: false,
      sampleIdPresent: false,
      filterFromChart:[],
      // timeoutMessage: "",
      // tableDataType: "",
      // localArray: [
      //   'Asset',
      //   'Field',
      //   'Well',
      //   'Reservoir',
      //   'Sample UID',
      // ],
    };
  },
  props: {
    selectedChartType: {
      type: String,
    },
  },
  mixins: [screenWidthMixin],
  computed: {
    // filteredColorGroupItems() {
    // return this.colorGroupItems.filter(item => this.localArray.includes(item.displayName));
    // },
    isFluidsDataStudio() {
      return this.$route.path === '/Fluidsdata-studio';
    },
    updateXBins() {
      this.$emit("update-x-bins", Number(this.xBins));
    },
    updateYBins() {
      this.$emit("update-y-bins", Number(this.yBins));
    },
    xBinsAsNumber() {
      return Number(this.xBins);
    },
    yBinsAsNumber() {
      return Number(this.yBins);
    },
    isFormValid() {
      const isBasicFieldsFilled =
        this.selectedChartTypeName &&
        this.chartTitle &&
        this.selectedTableTypeName;
      const isXAxisFilled = !this.showXAxis || this.selectedXAxisKey;
      const isYAxisFilled =
        !this.showYAxis ||
        (Array.isArray(this.selectedYAxisKey) &&
          this.selectedYAxisKey.length > 0);
      const isZAxisFilled = !this.showZAxis || this.selectedZAxisKey;

      return (
        isBasicFieldsFilled && isXAxisFilled && isYAxisFilled && isZAxisFilled
      );
    },
    shouldRenderChartList() {
      return this.shouldRenderComponent([
        "FluidsDataStudio"
      ]);
    },
  },
  watch: {
    selectedColorGroupKey(newValue) {
      if (newValue === "") {
        this.selectedColorGroupKey = null;
      }
    },
    rightContentStyle(newStyle) {
      this.$emit("update-right-content-style", newStyle);
    }
  },
  methods: {
    showDeleteConfirmation() {
      this.confirmDeleteDialog = true;
    },
    closeDeleteConfirmation() {
      this.confirmDeleteDialog = false;
    },
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
  //   retryGetTableData() {
  //   let selectedType = this.tableDataType;
  //   this.getTableData(selectedType);
  // },
    toggleInputs() {
      this.showInputs = !this.showInputs
    },
    resetCallDefaultFilter() {
      this.callDefaultFilter = false;
    },
    handleResetSavedFilter() {
      const savedChartData = localStorage.getItem("savedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData) {
          this.savedChartChangeLoad(chartData);
        }
      }
    },
    shouldRenderComponent(allowedRoutes) {
      return allowedRoutes.includes(this.$route.name);
    },
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },

    goToCreateChart() {
      this.resetChartData();
    },

    async tableTypeChange(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);
      localStorage.setItem("selectedTableType", this.selectedTableTypeName)

      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );
        if (response.data.statusCode === 200) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(column => column.displayName);
          columns.sort((a, b) => (a.columnSortOrder || 999) - (b.columnSortOrder || 999));
          // this.xAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.xAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));  // Filter for canChart
          // this.yAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.yAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
          // this.zAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.zAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
          // this.colorGroupItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.colorGroupItems = columns.filter(column => column.canGroupBy)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
        }
        this.resetAxisSelections();
      } catch (error) {
        console.log("Error", error);
      }

      // await this.getTableData(selectedType);
    },

    async tableTypeChangeAgainstFilter(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.resetAxisSelections();
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);
      localStorage.setItem("selectedTableType", this.selectedTableTypeName)

      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );

        if (response.data.statusCode === 200) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(column => column.displayName);
          columns.sort((a, b) => (a.columnSortOrder || 999) - (b.columnSortOrder || 999));
          // this.xAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.xAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));  // Filter for canChart
          // this.yAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.yAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
          // this.zAxisItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.zAxisItems = columns.filter(column => column.canChart)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
          // this.colorGroupItems = columns.map(column => ({ displayName: column.displayName, name: column.columnName }));
          this.colorGroupItems = columns.filter(column => column.canGroupBy)
          .map(column => ({ displayName: column.displayName, name: column.name, sortOrder: column.sortOrder }));
        }
      } catch (error) {
        console.log("Error", error);
      }
    },

    async getTableData() {
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
  // Retrieve the selected table type from localStorage
  var selectedType = localStorage.getItem("selectedTableType");

  // Initialize an array for selected columns
  let selectedColumns = [];

  // Safely add each key to selectedColumns if it exists and is not empty
  if (typeof this.selectedXAxisKey === "string" && this.selectedXAxisKey.trim() !== "") {
    selectedColumns.push(this.selectedXAxisKey.trim());
  }

  if (Array.isArray(this.selectedYAxisKey)) {
    // If selectedYAxisKey is an array, add its non-empty trimmed values
    selectedColumns.push(
      ...this.selectedYAxisKey
        .filter((key) => typeof key === "string" && key.trim() !== "")
        .map((key) => key.trim())
    );
  } else if (typeof this.selectedYAxisKey === "string" && this.selectedYAxisKey.trim() !== "") {
    selectedColumns.push(this.selectedYAxisKey.trim());
  }

  if (typeof this.selectedZAxisKey === "string" && this.selectedZAxisKey.trim() !== "") {
    selectedColumns.push(this.selectedZAxisKey.trim());
  }

  if (typeof this.selectedColorGroupKey === "string" && this.selectedColorGroupKey.trim() !== "") {
    selectedColumns.push(this.selectedColorGroupKey.trim());
  }

  // Filter out any invalid entries as an extra safety measure
  selectedColumns = selectedColumns.filter((column) => column);

  // If no columns are selected, exit early
  if (selectedColumns.length === 0) {
    return;
  }

  try {
    // Construct the API URL with the selectColumns query parameter
    const queryString = `?selectColumns=${JSON.stringify(selectedColumns)}`;
    const response = await api.get(
      `public/api/v1/tables/data/${selectedType}${queryString}`
    );

    // Check if the API response is successful
    if (response.data.statusCode === 200) {
      this.tableData = response.data.data.TableData.rows;
    }
  } catch (error) {
    console.log("Error", error);
    // Handle error as needed
  }
  finally {
        this.showLoaders = false;
        var showLoaders = this.showLoaders;
        this.$emit("loading-state", showLoaders);
      }
},

    async getTableDataAgainstFilter({ filterData, type }) {
      this.savedFilterData = filterData
      this.savedType = type
      this.$emit("sample-error", "");
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);

      try {
        const queryParams = {};

        const minMaxFormatter = ({ min = "", max = "" }) => JSON.stringify({ min, max });

        // Add filters to queryParams only if they have values
        if (filterData.asset && filterData.asset.length) queryParams.asset = JSON.stringify(filterData.asset);
        if (filterData.field && filterData.field.length) queryParams.field = JSON.stringify(filterData.field);
        if (filterData.well && filterData.well.length) queryParams.well = JSON.stringify(filterData.well);
        if (filterData.reservoir && filterData.reservoir.length) queryParams.reservoir = JSON.stringify(filterData.reservoir);
        if (filterData.sampleKind && filterData.sampleKind.length) queryParams.sampleKind = JSON.stringify(filterData.sampleKind);
        if (filterData.fluidSampleID && filterData.fluidSampleID.length) queryParams.fluidSampleID = JSON.stringify(filterData.fluidSampleID);
        if (filterData.fluidSampleContainerID && filterData.fluidSampleContainerID.length) queryParams.fluidSampleContainerID = JSON.stringify(filterData.fluidSampleContainerID);
        if (filterData.source) queryParams.source = `"${filterData.source}"`;
        if (filterData.sampleDate) queryParams.sampleDate = minMaxFormatter(filterData.sampleDate);
        if (filterData.depth) queryParams.depth = minMaxFormatter(filterData.depth);
        if (filterData.formationPressure) queryParams.formationPressure = minMaxFormatter(filterData.formationPressure);
        if (filterData.formationTemperature) queryParams.formationTemperature = minMaxFormatter(filterData.formationTemperature);

        // Add selectColumns logic here
    let selectedColumns = [];

// Add selected X/Y/Z keys
if (typeof this.selectedXAxisKey === "string" && this.selectedXAxisKey.trim() !== "") {
  selectedColumns.push(this.selectedXAxisKey.trim());
}
if (Array.isArray(this.selectedYAxisKey)) {
  selectedColumns.push(
    ...this.selectedYAxisKey
      .filter((key) => typeof key === "string" && key.trim() !== "")
      .map((key) => key.trim())
  );
} else if (typeof this.selectedYAxisKey === "string" && this.selectedYAxisKey.trim() !== "") {
  selectedColumns.push(this.selectedYAxisKey.trim());
}
if (typeof this.selectedZAxisKey === "string" && this.selectedZAxisKey.trim() !== "") {
  selectedColumns.push(this.selectedZAxisKey.trim());
}

if (typeof this.selectedColorGroupKey === "string" && this.selectedColorGroupKey.trim() !== "") {
    selectedColumns.push(this.selectedColorGroupKey.trim());
  }
  
  const chartsData = localStorage.getItem("savedChartData");
  const chartData = JSON.parse(chartsData);

if(chartData){
// Add X Axis column if valid
if (typeof chartData.XAxisColumn === "string" && chartData.XAxisColumn.trim() !== "") {
  selectedColumns.push(chartData.XAxisColumn.trim());
}

// Handle Y Axis column
if (typeof chartData.YAxisColumn === "string" && chartData.YAxisColumn.trim() !== "") {
  // Split the string by commas, trim each value, and push to selectedColumns
  selectedColumns.push(
    ...chartData.YAxisColumn.split(",").map((key) => key.trim())
  );
} else if (Array.isArray(chartData.YAxisColumn)) {
  // Push directly if it's an array
  selectedColumns.push(
    ...chartData.YAxisColumn
      .filter((key) => typeof key === "string" && key.trim() !== "")
      .map((key) => key.trim())
  );
}

// Add Z Axis key if valid
if (typeof chartData.ZAxisColumn === "string" && chartData.ZAxisColumn.trim() !== "") {
  selectedColumns.push(chartData.ZAxisColumn.trim());
}
}
const interval = setInterval(() => {
        if (this.chartTypes && this.chartTypes.length > 0) {
          clearInterval(interval); // Clear the interval when chartTypes contains data
          this.chartTypeChange(chartData?.ChartType)
        }
      }, 100);
// Add color group key if valid
// if (typeof this.selectedColorGroupKey === "string" && this.selectedColorGroupKey.trim() !== "") {
//   selectedColumns.push(this.selectedColorGroupKey.trim());
// }
  
// Add selectColumns to queryParams
if (selectedColumns.length > 0) {
  queryParams.selectColumns = JSON.stringify(selectedColumns);
}

        const queryString = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        const response = await api.get(`public/api/v1/tables/data/${type}?${queryString}`);

        if (response && response.data && response.data.statusCode === 200) {
          if(response.data.error) {
            this.$emit("sample-error", response.data.error);
            return
          }
          this.tableData = response.data.data.TableData.rows;

          const savedChartData = localStorage.getItem("savedChartData");
          // if (savedChartData) {
            const chartData = JSON.parse(savedChartData);
            if (chartData) {
              // this.tableTypeChangeAgainstFilter(chartData.TableType);
              // this.$nextTick(() => {
              //   this.populateChartDataInFilters(chartData);
              // });
              this.chartTitle = chartData.Name;
      var chartTitle = this.chartTitle;
      this.$emit("chart-title", chartTitle);
      this.selectedChartTypeName = chartData.ChartType;
      this.selectedTableTypeName = chartData.TableType;
      this.selectedColorGroupKey = chartData.GroupColumn;
      this.selectedXAxisKey = chartData.XAxisColumn;
      if(this.selectedColorGroupKey && chartData.YAxisColumn.length > 1){
        const yAxisColumnArray = chartData.YAxisColumn.split(",");
    yAxisColumnArray.pop();
    this.selectedYAxisKey = yAxisColumnArray;
      }
            }
          // }
          // else {
            var chartTitle = this.chartTitle
            this.$emit("chart-title", chartTitle);
              setTimeout(() => {
            if (this.selectedXAxisKey) {
        const selectedColumn = this.xAxisItems.find(
          (item) => item.name === this.selectedXAxisKey
        );
        this.xAxisData = this.findXAxisData();
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedXAxisKey });
        this.handleGroupSelection()
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      }
      if (this.selectedYAxisKey.length > 0) {
        if (this.selectedYAxisKey.length > 1) {
          this.selectedColorGroupKey = null;
        }
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map((key) => {
          const selectedColumn = this.yAxisItems.find(
            (item) => item.name === key
          );
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
        this.handleGroupSelection();
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.yAxisData = [];
        this.$emit("y-axis-selected", { data: [], displayNames: [] }); // Emit empty data
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      }
      if (this.selectedZAxisKey) {
        const selectedColumn = this.zAxisItems.find(
          (item) => item.name === this.selectedZAxisKey
        );
        this.zAxisData = this.findZAxisData();
        this.$emit("z-axis-selected", { data: this.zAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedZAxisKey });
        this.handleGroupSelection();
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      }
  }, 500);
          // }
        } else {
          // Handle unexpected response structure
          this.$emit("sample-error", "Unexpected response structure.");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          let sampleError = error.response.data.error;
          this.$emit("sample-error", sampleError);
        } else {
          this.$emit("sample-error", "An error occurred while fetching data.");
        }
      } finally {
        this.showLoaders = false;
        var showLoaders = this.showLoaders;
        this.$emit("loading-state", showLoaders);
      }
    },

    chartTypeChange(selectedType) {
      this.chartType = selectedType;
      const selectedChart = this.chartTypes.find(
        (chart) => chart.Name === selectedType
      );
      if (selectedChart) {
        this.showXBinsInput = selectedChart.has_x_bins;
        this.showYBinsInput = selectedChart.has_y_bins;
        this.selectedChartTypeName = selectedChart.Name;
        this.showXAxis = selectedChart.has_x;
        this.showYAxis = selectedChart.has_y;
        if (this.showYAxis == false) {
          this.selectedYAxisKey = [];
          this.handleYAxisSelection();
        }
        this.showZAxis = selectedChart.has_z;
        this.showColorGroup = selectedChart.has_group
        if (this.showColorGroup == false) {
          this.selectedColorGroupKey = null;
          this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
          this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
          this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        }
        this.$emit("chart-type-changed", selectedType);
      } else {
        console.error("Selected chart not found.");
      }
    },

    async savedChartChange() {
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      let defaultFilterID = localStorage.getItem("defaultFilter");
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (selectedChart) {
        const chartId = selectedChart.ID;

        try {
          const response = await api.get(`public/api/v1/charts/${chartId}`);

          if (response.data.statusCode === 200) {
            let selectedChartData = response.data.data.chart;
            this.accessLevel = selectedChartData.AccessLevel;
            await this.tableTypeChange(selectedChartData.TableType);
            localStorage.setItem("savedChartData", JSON.stringify(selectedChartData));
            this.filterFromChart = selectedChartData.Filter;
            if(this.filterFromChart){
            this.$refs.filters.isFilterSelected();
            return
          }

            if (selectedChart && defaultFilterID) {
              this.callDefaultFilter = true;
              return;
            }
            this.showLoaders = false;
            var showLoaders = this.showLoaders;
            this.$emit("loading-state", showLoaders);
            this.$nextTick(() => {
              this.populateChartData(selectedChartData);
            });
            this.createNewChart = true;
          }
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        console.error("Selected chart not found.");
      }
    },

    async savedChartChangeLoad(chartData) {
      this.$emit("sample-error", "");
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      this.selectedSavedChartName = chartData.Name;
      let selectedChartData = chartData;
      await this.tableTypeChange(selectedChartData.TableType);
      this.accessLevel = selectedChartData.AccessLevel
      let defaultFilterID = localStorage.getItem("defaultFilter");
      this.filterFromChart = chartData.Filter;
      if(this.filterFromChart) {
      return;
    }
      if (defaultFilterID) {
        return;
      }
      this.showLoaders = false;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      this.$nextTick(() => {
        this.populateChartData(selectedChartData);
      });
      this.createNewChart = true;
    },

    async populateChartData(chartData) {
      this.chartTitle = chartData.Name;
      var chartTitle = this.chartTitle;
      this.$emit("chart-title", chartTitle);
      this.selectedChartTypeName = chartData.ChartType;
      this.selectedTableTypeName = chartData.TableType;
      this.selectedColorGroupKey = chartData.GroupColumn;
      // await this.getTableData(chartData)

      // setTimeout(() => {

      const selectedXAxisItem = this.xAxisItems.find(
        (item) => item.name === chartData.XAxisColumn
      );
      this.selectedXAxisKey = selectedXAxisItem ? selectedXAxisItem.name : null;

      if (this.tableData && chartData.XAxisColumn) {
        const columnData = this.tableData.map(
          (row) => row[chartData.XAxisColumn]
        );
        this.xAxisData = columnData;
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: columnData ? columnData.displayName : chartData.XAxisColumn });
        this.handleGroupSelection()
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      }
        if (typeof chartData.YAxisColumn === "string" && chartData.YAxisColumn.length > 0) {
    const yAxisColumnArray = chartData.YAxisColumn.split(",");
    if (this.selectedColorGroupKey && yAxisColumnArray.length > 1) {
      yAxisColumnArray.pop(); // Remove the last element if selectedColorGroupKey is present
    }
    this.selectedYAxisKey = yAxisColumnArray.map((yAxisColumnName) => {
      const selectedYAxisItem = this.yAxisItems.find(
        (item) => item.name === yAxisColumnName
      );
      return selectedYAxisItem ? selectedYAxisItem.name : null;
    });

    if (this.tableData && chartData.YAxisColumn.length > 0) {
      const columnData = yAxisColumnArray.map((col) => {
        return this.tableData.map((row) => row[col]);
      });
      this.yAxisData = columnData;
      this.$emit("y-axis-selected", { data: this.yAxisData });
        this.handleGroupSelection();
    }
  } else {
    this.selectedYAxisKey = [];
  }

      const selectedZAxisItem = this.zAxisItems.find(
        (item) => item.name === chartData.ZAxisColumn
      );
      this.selectedZAxisKey = selectedZAxisItem ? selectedZAxisItem.name : null;

      this.xBins = chartData.NumXBins;
      this.yBins = chartData.NumYBins;
      this.updateXBins;
      this.updateYBins;

      // Check if chartTypes array is empty and set an interval
      const interval = setInterval(() => {
        if (this.chartTypes && this.chartTypes.length > 0) {
          clearInterval(interval); // Clear the interval when chartTypes contains data
          this.chartTypeChange(this.selectedChartTypeName);
        }
      }, 100);
      this.updateAxisSelections();
      localStorage.setItem("savedChartData", JSON.stringify(chartData));
    // }, 1);
    },

    updateAxisSelections() {
      this.handleXAxisSelection();
      this.handleYAxisSelection();
      this.handleZAxisSelection();
    },

    findXAxisData() {
      if (this.tableData && this.selectedXAxisKey) {
        const columnData = this.tableData.map(
          (row) => row[this.selectedXAxisKey]
        );
        return columnData;
      }
      return [];
    },

    findYAxisData() {
      if (this.tableData && this.selectedYAxisKey.length > 0) {
        const columnData = this.selectedYAxisKey.map((col) => {
          return this.tableData.map((row) => row[col]);
        });
        return columnData;
      }
      return [];
    },

    findZAxisData() {
      if (this.tableData && this.selectedZAxisKey) {
        const columnData = this.tableData.map(
          (row) => row[this.selectedZAxisKey]
        );
        return columnData;
      }
      return [];
    },

    findcolorGroupData() {
      if (this.tableData && this.selectedColorGroupKey) {
        const columnData = this.tableData.map(row => row[this.selectedColorGroupKey]);
        const uniqueValues = [...new Set(columnData.filter(item => item !== undefined && item !== null))];
        return uniqueValues;
      }
      return [];
    },

    async handleXAxisSelection() {
      this.axisSelected = true;
      if (this.selectedXAxisKey) {
        if(this.sampleIdPresent && this.showColorGroup) {
          this.selectedColorGroupKey = "FluidSampleID";
        }
        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterApplied = localStorage.getItem("filterApplied")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        if (defaultFilterID || filterApplied || selectedFilterId) {
        let filterData = this.savedFilterData
          let type = this.savedType
        await this.getTableDataAgainstFilter({filterData, type});
      }
      else {
        await this.getTableData();
      }
        const selectedColumn = this.xAxisItems.find(
          (item) => item.name === this.selectedXAxisKey
        );
        this.xAxisData = this.findXAxisData();
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedXAxisKey });
        this.handleGroupSelection()
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      }
    },

    async handleYAxisSelection() {
      this.axisSelected = true;
      if (this.selectedYAxisKey.length > 0) {
        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterApplied = localStorage.getItem("filterApplied")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        if (defaultFilterID || filterApplied || selectedFilterId) {
        let filterData = this.savedFilterData
          let type = this.savedType
        await this.getTableDataAgainstFilter({filterData, type});
      }
      else {
        await this.getTableData();
      }
        if (this.selectedYAxisKey.length > 1) {
          this.selectedColorGroupKey = null;
        }
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map((key) => {
          const selectedColumn = this.yAxisItems.find(
            (item) => item.name === key
          );
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
        this.handleGroupSelection();
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.yAxisData = [];
        this.$emit("y-axis-selected", { data: [], displayNames: [] }); // Emit empty data
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      }
    },

    async handleZAxisSelection() {
      this.axisSelected = true;
      if (this.selectedZAxisKey) {
        let defaultFilterID = localStorage.getItem("defaultFilter");
        let filterApplied = localStorage.getItem("filterApplied")
        let selectedFilterId = localStorage.getItem("selectedFilterId")
        if (defaultFilterID || filterApplied || selectedFilterId) {
        let filterData = this.savedFilterData
          let type = this.savedType
        await this.getTableDataAgainstFilter({filterData, type});
      }
      else {
        await this.getTableData();
      }
        const selectedColumn = this.zAxisItems.find(
          (item) => item.name === this.selectedZAxisKey
        );
        this.zAxisData = this.findZAxisData();
        this.$emit("z-axis-selected", { data: this.zAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedZAxisKey });
        this.handleGroupSelection();
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      }
    },

    handleColorGroupSelection () {
      this.axisSelected = true;
      this.colorGroupChanged = true;
      this.handleGroupSelection()
    },

    async handleGroupSelection() {
      if (this.selectedColorGroupKey && this.selectedColorGroupKey.length > 0) {
        if (this.colorGroupChanged) {
          let defaultFilterID = localStorage.getItem("defaultFilter");
          let filterApplied = localStorage.getItem("filterApplied")
          let selectedFilterId = localStorage.getItem("selectedFilterId")
        if (defaultFilterID || filterApplied || selectedFilterId) {
        let filterData = this.savedFilterData
          let type = this.savedType
        await this.getTableDataAgainstFilter({filterData, type});
      }
      else {
        await this.getTableData();
      }
      }
      this.colorGroupChanged = false
        this.colorGroupData = this.findcolorGroupData();

        if (this.selectedChartTypeName != "Histogram") {
          // Check if yAxisData is present
          if (!this.yAxisData.length) {
            return;
          }
        }

        // Check if xAxisData is present
        if (!this.xAxisData.length) {
          return;
        }

        if (this.selectedYAxisKey.length > 1) {
          this.selectedYAxisKey = this.selectedYAxisKey.slice(0, 1);

          const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
            const selectedColumn = this.yAxisItems.find(item => item.name === key);
            return selectedColumn ? selectedColumn.displayName : key;
          });
          this.$emit("y-axis-selected", { displayNames: yAxisDisplayNames });
        }

        // Initialize an object to store grouped yAxisData based on uniqueValues
        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        // Initialize the grouped arrays for each unique value
        this.colorGroupData.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        // Iterate through tableData and group yAxisData and xAxisData
        this.tableData.forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKey];

          if (this.colorGroupData.includes(colorGroupValue)) {
            // Collect yAxis data
            const yAxisRowData = this.selectedYAxisKey.map(key => row[key]);
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            // Collect xAxis data
            const xAxisRowData = row[this.selectedXAxisKey];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            // Collect zAxis data
            const zAxisRowData = row[this.selectedZAxisKey];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });
        let selectedColorGroupKey = this.selectedColorGroupKey

        // Emit the grouped yAxis and xAxis data
        this.$emit("grouped-y-axis-data", (groupedYAxisData));
        this.$emit("grouped-x-axis-data", (groupedXAxisData));
        this.$emit("grouped-z-axis-data", (groupedZAxisData));
        this.$emit("color-group-key", (selectedColorGroupKey));
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
          const selectedColumn = this.yAxisItems.find(item => item.name === key);
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
      }
    },

    resetAxisSelections() {
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      const containsSampleUID = this.colorGroupItems.some(
      item => item.displayName === "Sample UID"
      );

      if (containsSampleUID) {
        this.sampleIdPresent = true;
      // this.selectedColorGroupKey = "SampleID";
      } else {
      this.selectedColorGroupKey = null;
     }
      this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      this.xAxisData = [];
      this.$emit("x-axis-selected", { data: this.xAxisData });
      this.yAxisData = [];
      this.$emit("y-axis-selected", { data: this.yAxisData });
      this.zAxisData = [];
      this.$emit("z-axis-selected", { data: this.zAxisData });
    },

    getChartTypes() {
      this.loadingChartType = true;
      api
        .get("public/api/v1/charts/type")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.chart_types;
            this.chartTypesNames = response.data.data.chart_types.map(
              (chart) => chart.Name
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.loadingChartType = false;
        });
    },
    getTableTypes() {
      this.showLoaders = true;
      this.loadingTableType = true;
      api
        .get("public/api/v1/tables/type")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.tableTypes = response.data.data.tabletypes;
            this.tableTypesNames = response.data.data.tabletypes.map(
              (chart) => chart.table
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingTableType = false;
        });
    },
    createChart() {
      this.createLoading = true;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );
      // Initialize YAxisColumns as an array and include selectedYAxisKey values if showYAxis is true
      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];

      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }
      const Filters = this.$refs.filters.saveFilterWithChart();
      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: "private",
            Filter: Filters
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        // requestData.data.chart.GroupColumn = ""
      }
      api
        .post("public/api/v1/charts", requestData)
        .then((response) => {
          if (response.data.statusCode === 201) {
            this.createLoading = false;
            this.accessLevel = requestData.data.chart.AccessLevel;
            this.showSnackbar(response.data.message, "success");
            this.getSavedCharts();
            // this.resetChartData();
            this.$emit("chart-saved-studio", requestData.data.chart);
            this.createNewChart = true;
            this.selectedSavedChartName = requestData.data.chart.name;
            let chartId = response.data.data;
            this.getChartById(chartId);
          }
        })
        .catch((error) => {
          this.createLoading = false;
          this.showSnackbar(error.response.data.error, "error");
        });
    },
    async deleteChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.deleteLoading = true;
      const chartId = selectedChart.ID;

      try {
        const response = await api.delete(`public/api/v1/charts/${chartId}`);

        if (response.data.statusCode === 200) {
          this.deleteLoading = false;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          this.resetChartData();
          this.chartSaved = true;
          var chartSaved = this.chartSaved;
          this.$emit("chart-saved", chartSaved);
          this.confirmDeleteDialog = false;
        }
      } catch (error) {
        this.deleteLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },

    async updateChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.updateLoading = true;
      const chartId = selectedChart.ID;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );

      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];

      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }
      const Filters = this.$refs.filters.saveFilterWithChart();
      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: "private",
            Filter: Filters
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = ""
      }
      try {
        const response = await api.put(
          `public/api/v1/charts/${chartId}`,
          requestData
        );

        if (response.data.statusCode === 200) {
          this.updateLoading = false;
          this.accessLevel = requestData.data.chart.AccessLevel;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          // this.resetChartData();
          this.chartSaved = true;
          var chartSaved = this.chartSaved;
          this.$emit("chart-saved", chartSaved);
          this.getChartById(chartId);
        }
      } catch (error) {
        this.updateLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },
    async getChartById(chartId) {
      try {
        const response = await api.get(
          `public/api/v1/charts/${chartId}`
        );

        if (response.data.statusCode === 200) {
          let chartDataById = response.data.data.chart;
          localStorage.setItem("savedChartData", JSON.stringify(chartDataById));
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetChartData() {
      this.showLoaders = true;
      this.chartTitle = "";
      this.resetAxisSelections();
      this.$emit("chart-type-changed", "");
      this.$emit("chart-title", "");
      this.showXBinsInput = false;
      this.showYBinsInput = false;
      this.showZAxis = false;
      this.showColorGroup = false;
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedSavedChartName = null;
      this.showLoaders = false;
      this.createNewChart = false;
      localStorage.removeItem("savedChartData");
      this.showInputs = true
    },
    getSavedCharts() {
      this.showLoaders = true;
      this.loadingSavedCharts = true;
      api
        .get("public/api/v1/charts")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.savedCharts = response.data.data.charts;
            this.savedChartsNames = response.data.data.charts.map(
              (chart) => chart.Name
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingSavedCharts = false;
        });
    },
  },
  mounted() {
    if (this.$route.path === '/Fluidsdata-studio') {
      const savedChartData = localStorage.getItem("savedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData) {
          this.savedChartChangeLoad(chartData);
        }
      }
    }
    if (this.$route.path === '/Visualization-&-Dashboards') {
      this.showInputs = true;
      this.chartTitle = "";
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedColorGroupKey = null;
    }
    this.getChartTypes();
    this.getTableTypes();
    this.getSavedCharts();
  },
};
</script>