<template>
<div class="auth-container">
  <MainAuth />
  <Form />
</div>

</template>

<script>
import MainAuth from "../../components/layout/AuthLeft/MainAuth.vue";
import Form from "../../components/layout/AuthRight/Form.vue";
export default {
  name: "Login",
  components: { MainAuth, Form },
};
</script>