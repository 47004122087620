<template>
  <div class="dashboard-page-wrapper test-page">
    <div class="top-wrap">
      <div class="text-img">
        <h2> Composition Analysis </h2> 
        <div v-if="receivedData">
          <div v-if="pdfLoader === true" class="test-pdf-div">
            <div class="img-content">
            <img :src="pdf_loader_image" alt="loader Image" />
            </div>
          </div>
          <div v-else-if="receivedData.testID !== ''" @click="goToPdf()" class="test-pdf-div">
            <div class="img-content">
            <img :src="pdf_icon" alt="pdf icon" />
            </div>
            <div class="pdf-text">Download PDF</div>
          </div>
        </div>
      </div>
      <div class="top-content" v-if="receivedData && receivedData.testID != ''">
        <!-- <div v-if="receivedData.testID != ''">
          <div class="top-content-data"> -->
        <v-img src="../../../assets/Images/Assets/top image.png" alt="test image" width="49px" height="49px"></v-img>
        <div class="fields-info" :style="{
          flexDirection: (receivedData.saturationPressure && receivedData.testTemperature) ? 'row' : 'column',
          gap: (receivedData.saturationPressure && receivedData.testTemperature) ? '24px' : '8px'
        }" >
          <div class="fields-info-wrap">
            <div>
              <p>Test ID</p>
              <p class="test-top-values">{{ receivedData.testID }}</p>
            </div>
            <div v-if="receivedData.fluidSampleID">
              <p>Sample ID</p>
              <p class="test-top-values">{{ receivedData.fluidSampleID }}</p>
            </div>
          </div>
          <div class="fields-info-wrap"> 
            <div v-if="receivedData.saturationPressure_UOM && receivedData.saturationPressure">
              <p>Saturation Pressure</p>
              <p class="test-top-values">{{
                receivedData.saturationPressure }}
                <span> ({{
                  receivedData.saturationPressure_UOM }})
                </span>
              </p>
            </div>
            <div v-if="receivedData.testTemperature && receivedData.testTemperature_UOM">
              <p>Test Temperature</p>
              <p class="test-top-values">{{
                receivedData.testTemperature }}
                <span> ({{
                  receivedData.testTemperature_UOM }})
                </span>
              </p>
            </div>
          </div>
        </div>

        <div v-if="receivedData.testID == ''">
          No data available.
        </div>
      </div>


      <div class="top-content" v-else>
        <test-loaders />
      </div>
    </div>
    <div ref="scrollable" class="tabs-data" tabindex="0" @keydown="handleKeyDown">
      <div class="tabs-toggle">
        <v-tabs v-model="tab">
          <v-tab value="one">Composition Analysis</v-tab>
          <v-tab value="two">Composition Properties</v-tab>
        </v-tabs>

        <div v-if="tab === 'one'">
          <v-switch v-model="toggleFractionParent" color="info" inset :label="toggleFractionParent ? 'wt %' : 'mole %'"
            @change="handleToggleChange" hide-details></v-switch>
            
        </div>
      </div>

      <v-card-text class="pa-0">
        <v-window v-model="tab">
          <v-window-item value="one">
            <CompositionAnalysisTab ref="compositionAnalysisTab" @toggle-fraction="updateToggleFractionAnalysis" @data-updated="handleDataUpdate" />
          </v-window-item>

          <v-window-item value="two">
            <CompositionPropertiesTab ref="compositionPropertiesTab" @toggle-fraction="updateToggleFractionProperties"  @data-updated="handleDataUpdate" />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
    <!-- <Filters @drawerStateChanged="handleRightDrawerStateChange" /> -->
  </div>
</template>

<script>
import CompositionAnalysisTab from "./Tables/CompositionAnalysisTab.vue";
import CompositionPropertiesTab from "../CompositionAnalysis/Tables/CompositionPropertiesTab.vue";
import TestLoaders from '../Loaders/TestLoaders.vue';
// import Filters from "../../../components/layout/Common/Filters.vue";
// import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import axios from "axios";
export default {
  components: { CompositionAnalysisTab, CompositionPropertiesTab, TestLoaders },
  name: "CompositionTabs",
  data: () => ({
    toggleFractionParent: true,
    tab: null,
    receivedData: null,
    assetName: "",
    fieldName: "",
    wellName: "",
    pdf_icon: require("../../../assets/Images/pdfIcon.svg"),
    pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
    pdfLoader: false,
    snackbarMessage: null,
  }),
  // mixins: [screenWidthMixin],
  methods: {
    handleToggleChange() {
      // Update CompositionAnalysisTab if the reference exists
      if (this.$refs.compositionAnalysisTab) {
        this.$refs.compositionAnalysisTab.toggleFraction = this.toggleFractionParent; // Sync state with the child
        this.$refs.compositionAnalysisTab.toggleFractionFunction(); // Call the child's method
      }

      // Update CompositionPropertiesTab if the reference exists
      if (this.$refs.compositionPropertiesTab) {
        this.$refs.compositionPropertiesTab.toggleFraction = this.toggleFractionParent; // Sync state with the child
        this.$refs.compositionPropertiesTab.toggleFractionFunction(); // Call the child's method
      }
    },
    updateToggleFractionAnalysis(value) {
      this.toggleFractionParent = value; // Update the parent state when child emits an event
    },
    updateToggleFractionProperties(value) {
      this.toggleFractionParent = value; // Update the parent state when child emits an event
    },
    handleDataUpdate(data) {
      this.receivedData = data;
    },
    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.snackbarMessage = "PDF data is not available."
            this.pdfLoader = false;
          }
        })
        .finally(() => {
          this.pdfLoader = false;
        });
    },
    handleKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowDown') {
        // Handle scrolling down
        this.$refs.scrollable.scrollTop += 50; // You can adjust the scrolling speed as needed
      } else if (key === 'ArrowLeft') {
        // Handle switching to the previous tab
        const currentIndex = this.tab ? ['one', 'two'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 0 ? 'two' : 'one';
      } else if (key === 'ArrowRight') {
        // Handle switching to the next tab
        const currentIndex = this.tab ? ['one', 'two'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 1 ? 'one' : 'two';
      }
    },
  },
  mounted() {
    this.tab = 'one';
    this.assetName = this.$route.params.asset.replace(/-/g, ' ');
    this.fieldName = this.$route.params.field.replace(/-/g, ' ');
    this.wellName = this.$route.params.well;
    this.$refs.scrollable.focus();
  },
};
</script>